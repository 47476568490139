import React, { useState, useEffect } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import { Sparklines, SparklinesLine } from 'react-sparklines';
// Other necessary imports (e.g., for the bar chart)
import Sidebar from './Sidebar';
import TopBar from './TopBar';
import ProjectTable from './ProjectTable';
import CombinationChart from './CombinationChart';
import LoadingScreen from './LoadingScreen';
import SearchIcon from '@mui/icons-material/Search'; // Importing the search icon
import ImportUrlsModal from './ImportUrlsModal'; // Import the modal component
import AddCircleIcon from '@mui/icons-material/AddCircle'; // Add URLs
import { useTaskStatus } from './TaskStatusContext'; // Adjust path as necessary

const ProjectOverview = () => {
    const { projectId } = useParams();
    const [projectData, setProjectData] = useState(null);
    const history = useNavigate(); // Create history instance
    const [showSuccessMessage, setShowSuccessMessage] = useState(false);
    const [selectedUrls, setSelectedUrls] = useState(new Set());
    const [searchTerm, setSearchTerm] = useState('');
    // Initially set to false to show all URLs if no Enhance Plan URLs exist
    const [showEnhancePlanUrlsOnly, setShowEnhancePlanUrlsOnly] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false); // State to manage modal visibility
    const [isLoading, setIsLoading] = useState(true); // Loading state
    const { startTask, completeTask } = useTaskStatus(); // Use the hook
    const [error, setError] = useState(null);
    const [urlsData, setUrlsData] = useState([]);
    const [urlsSubmitted, setUrlsSubmitted] = useState(false);
    const [isPropertyIdMissing, setIsPropertyIdMissing] = useState(false); 
    const location = useLocation(); 
    const [userRole, setUserRole] = useState('');
    const navigate = useNavigate(); // Hook to programmatically navigate
    const [enhancePlanUrlsCount, setEnhancePlanUrlsCount] = useState(null);
    const [isConsentChecked, setIsConsentChecked] = useState(false);
    const [isSelectAllChecked, setIsSelectAllChecked] = useState(false); // State for the checkbox
    const [verifiedEmail, setVerifiedEmail] = useState(null);
    const accessCheckUrl = `/projects/${projectId}/ai-access-check`;
    const [showMobileModal, setShowMobileModal] = useState(false);
    
    useEffect(() => {
        const checkAuthentication = async () => {
            try {
                const response = await axios.get('https://api.contentenhance.com/api/auth/check', { withCredentials: true });
                if (!response.data.isAuthenticated) {
                    navigate('/login'); // Redirect to login if not authenticated
                }
            } catch (error) {
                console.error('Authentication check failed:', error);
                navigate('/login'); // Redirect to login on error
            }
        };

        checkAuthentication();
    }, [navigate]);
    
    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth < 1250) {
                setShowMobileModal(true);
            } else {
                setShowMobileModal(false);
            }
        };

        // Initial check
        handleResize();

        // Listen for resize events
        window.addEventListener('resize', handleResize);

        // Cleanup the event listener on unmount
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
    
	useEffect(() => {
	  let retryCount = 0;
	  const MAX_RETRIES = 10;

	  const fetchProjectData = async () => {
	    if (retryCount === 0) {
	      setIsLoading(true);
	    }

	    try {
	      const response = await axios.get(
	        `https://api.contentenhance.com/api/projects/${projectId}`,
	        { withCredentials: true }
	      );
	      const fetchedData = response.data || {};

	      // Check if propertyID is missing.
	      if (!fetchedData.propertyID) {
	        setIsPropertyIdMissing(true);
	        setIsLoading(false);
	        setProjectData(fetchedData);
	        return;
	      }

	      // If 'urls' exists and is an array, retry until there's at least one URL (or max retries reached)
	      if (fetchedData.urls && Array.isArray(fetchedData.urls)) {
	        if (fetchedData.urls.length > 0 || retryCount >= MAX_RETRIES) {
	          setProjectData(fetchedData);
	          setIsLoading(false);
	        } else {
	          retryCount++;
	          setTimeout(fetchProjectData, 10000); // Retry in 10 seconds
	        }
	      } else {
	        // If 'urls' is not an array or not present, accept the data as-is.
	        setProjectData(fetchedData);
	        setIsLoading(false);
	      }
	    } catch (error) {
	      // If the error is a 403, redirect to the Forbidden page.
	      if (error.response && error.response.status === 403) {
	        navigate('/403');
	        return;
	      }
	      console.error('Error fetching project data:', error);
	      setError(error);
	      setIsLoading(false);
	    }
	  };

	  fetchProjectData();

	  // Cleanup: Stop any pending retries when the component unmounts.
	  return () => {
	    retryCount = MAX_RETRIES;
	  };
	}, [projectId, navigate]);
    
    
    const fetchUserRoleForProject = async (projectId) => {
        try {
            const response = await axios.get(`https://api.contentenhance.com/api/projects/${projectId}/check_role`, { withCredentials: true });
            if (response.status === 200) {
                return response.data.role;
            }
        } catch (error) {
            console.error('Error fetching user role for project:', error);
            // Handle error (e.g., user not authenticated or doesn't have a role in the project)
        }
    };
    
    useEffect(() => {
        const fetchEnhancePlanUrlsCount = async () => {
            try {
                const response = await axios.get(`https://api.contentenhance.com/api/enhance-plan-urls-count`, {
                    params: { project_id: projectId },
                    withCredentials: true
                });
                if (response.status === 200) {
                    setEnhancePlanUrlsCount(response.data.enhance_plan_urls_count);
                    setVerifiedEmail(response.data.verified_email);
                }
            } catch (error) {
                console.error('Error fetching enhance plan URLs count:', error);
            } finally {
                setUrlsSubmitted(false); // Reset the state here
            }
        };

        fetchEnhancePlanUrlsCount();
    }, [projectId, urlsSubmitted]);

    // Function to handle the opening of the modal
    const handleOpenModal = () => setIsModalOpen(true);

    // Function to handle the closing of the modal
    const handleCloseModal = () => setIsModalOpen(false);
    
    const setUrlsSubmittedToTrue = (submittedUrlIds) => {
        // Use the functional form of setSelectedUrls to ensure we always have the most current state
        setSelectedUrls((currentSelectedUrls) => {
            // Create a new Set to avoid direct mutation of the state
            const newSelectedUrls = new Set(currentSelectedUrls);
            // Add each submitted URL ID to the new Set
            submittedUrlIds.forEach((id) => newSelectedUrls.add(id));
            return newSelectedUrls;
        });

        setUrlsSubmitted(true);
        setShowEnhancePlanUrlsOnly(true);

        // Log to verify the IDs are passed correctly
        console.log(submittedUrlIds);
    };
    
    const handleSelectAllChange = () => {
        setIsSelectAllChecked(!isSelectAllChecked);
        if (!isSelectAllChecked) {
            const allUrlIds = projectData.urls.map(url => url.url_id);
            setSelectedUrls(new Set(allUrlIds));
        } else {
            setSelectedUrls(new Set());
        }
    };
    
    const handleSubmit = async () => {
        if (!['admin', 'editor'].includes(userRole)) {
            alert("You don't have permission to perform this action.");
            return;
        }

        const remainingUrls = projectData.enhance_plan_limit - enhancePlanUrlsCount;
        if (remainingUrls < selectedUrls.size) {
            window.location.href = 'https://www.contentenhance.com/pricing';
            return;
        }
        
        const urlIdsToAdd = Array.from(selectedUrls);
        console.log('Submitting URLs:', urlIdsToAdd);

        const currentEnhancePlanUrlsCount = enhancePlanUrlsCount;
        const isAddingBeyondLimit = currentEnhancePlanUrlsCount + selectedUrls.size > projectData.enhance_plan_limit;
        const hasExceededSwaps = projectData.number_of_swaps === 0;
        const withinSwapLimitAdditions = currentEnhancePlanUrlsCount <= projectData.swap_limit;

        if (isAddingBeyondLimit && (hasExceededSwaps && !withinSwapLimitAdditions)) {
            alert("You cannot add more URLs because you have tried to substitute (remove and add) too many URLs this month.");
            return;
        }

        try {
            urlIdsToAdd.forEach(urlId => startTask(urlId));
            await Promise.all(urlIdsToAdd.map(urlId => addToEnhancePlan(urlId)));
            console.log('URLs added to Enhance Plan');

            const updatedUrls = projectData.urls.map(url => {
                if (urlIdsToAdd.includes(url.url_id)) {
                    return { ...url, is_in_enhance_plan: true };
                }
                return url;
            });
            setProjectData({ ...projectData, urls: updatedUrls });

            const response = await takeScreenshots(urlIdsToAdd);
            console.log('Screenshot task initiated:', response.data);

            const taskId = response.data.task_ids;
            const updatedUrlsData = urlsData.map((urlData) => ({
                ...urlData,
                taskId: taskId,
            }));

            setUrlsData(updatedUrlsData);
            setUrlsSubmitted(true);
        } catch (error) {
            console.error('Error in submission process:', error);
        } finally {
            setSelectedUrls(new Set());
        }
    };

    const addToEnhancePlan = async (urlId) => {
        try {
            const response = await axios.post(`https://api.contentenhance.com/api/add-to-enhance-plan/${urlId}`);
            if (response.status === 200) {
                setProjectData(currentData => ({
                    ...currentData,
                    urls: currentData.urls.map(url =>
                        url.url_id === urlId ? { ...url, showSuccess: true } : url
                    )
                }));
            }
        } catch (error) {
            console.error('Error adding URL to enhance plan:', error);
        }
    };

    const resendVerificationEmail = async () => {
        try {
            const response = await axios.post('https://api.contentenhance.com/api/resend-verification-email', {}, { withCredentials: true });
            if (response.status === 200) {
                alert('Verification email has been resent.');
            }
        } catch (error) {
            console.error('Error resending verification email:', error);
            alert('There was an error resending the verification email.');
        }
    };

    useEffect(() => {
        const fetchAndSetUserRole = async () => {
            // Assuming you have projectId and a function to fetch the role
            const role = await fetchUserRoleForProject(projectId);
            setUserRole(role);
        };

        fetchAndSetUserRole();
    }, [projectId]); // Re-fetch if projectId changes

    useEffect(() => {
        // Parse the query parameters
        const queryParams = new URLSearchParams(location.search);
        const showModal = queryParams.get('importUrls'); // Assuming 'importUrls=true' is the query parameter to open the modal

        // Open the modal if 'importUrls=true' is present in the URL
        if (showModal === 'true') {
            setIsModalOpen(true);
        }
    }, [location]); // Depend on location to re-run this effect when the URL changes

    useEffect(() => {
        const fetchInterval = 30000; // 30 seconds - adjust as needed
        let timeoutId;

        const fetchProjectData = async () => {
            try {
                const response = await axios.get(`https://api.contentenhance.com/api/projects/${projectId}`, { withCredentials: true });
                const fetchedData = response.data || {};
                // Update state only if there's new or updated data
                if (JSON.stringify(projectData) !== JSON.stringify(fetchedData)) {
                    setProjectData(fetchedData);
                }
            } catch (error) {
                console.error('Error fetching project data:', error);
                if (error.response && error.response.status === 401) {
                    navigate('/login');
                }
            }
        };

        // Define a function to start the polling process
        const startPolling = () => {
            // Initial fetch
            fetchProjectData();

            // Set up polling
            const intervalId = setInterval(fetchProjectData, fetchInterval);

            // Set a timeout to stop the interval after a certain period of inactivity
            timeoutId = setTimeout(() => clearInterval(intervalId), 500000); // Adjust the time as needed

            // Return the intervalId for cleanup
            return intervalId;
        };

        let intervalId = startPolling(); // Start polling immediately

        // Reset polling if new URLs are submitted
        if (urlsSubmitted) {
            clearTimeout(timeoutId);
            clearInterval(intervalId);
            intervalId = startPolling(); // Restart polling
            setUrlsSubmitted(false); // Reset urlsSubmitted state
        }

        // Cleanup function
        return () => {
            clearInterval(intervalId);
            clearTimeout(timeoutId);
        };
    }, [projectId, projectData, urlsSubmitted]);

    // Loading state
    if (isLoading) {
        return <LoadingScreen message="Fetching data for your project - this should only take a minute or two" />;
    }

    // Error state
    if (error) {
        return <div>Error loading project data: {error.message}</div>;
    }

    if (!projectData) {
        // Show a loading spinner or a message indicating data is being loaded
        return <LoadingScreen message="Fetching project data..." />;
    }

    const hasEnhancePlanUrls = projectData?.urls?.some(url => url.is_in_enhance_plan);

    // Determine if at least one checkbox is selected
    const isAnyCheckboxSelected = selectedUrls.size > 0;

    // Updated filtering logic for URLs:
    let urlsToShow = projectData?.urls
        ? projectData.urls.filter(url => {
              // Always apply search term filtering:
              if (searchTerm && !url.url.toLowerCase().includes(searchTerm.toLowerCase())) {
                  return false;
              }
              // Even if propertyID is missing, we now still filter by the search term.
              if (isPropertyIdMissing) {
                  return true;
              }
              // If there are Enhance Plan URLs and we are NOT specifically showing only Enhance Plan URLs,
              // only show URLs that are in the Enhance Plan.
              if (hasEnhancePlanUrls && !showEnhancePlanUrlsOnly) {
                  return url.is_in_enhance_plan;
              }
              // Otherwise, show the URL.
              return true;
          })
        : [];

    const takeScreenshots = async (urlIds) => {
        console.log('Sending request with payload:', { url_ids: urlIds, project_id: projectId });
        try {
            // This axios.post request returns a promise, which resolves when the request completes
            const response = await axios.post(
                `https://api.contentenhance.com/take_screenshots`,
                { url_ids: urlIds, project_id: projectId },
                { withCredentials: true }
            );
            return response; // Assuming the response includes screenshots_info
        } catch (error) {
            console.error('Error taking screenshots for added URLs:', error);
            throw error; // Throw an error to indicate failure
        }
    };

    return (
        <div className="flex flex-col h-screen bg-darkBlue">
            <TopBar />
            <div className="flex h-screen bg-lighterDarkBlue text-white">
                <Sidebar onUrlsSubmitted={setUrlsSubmittedToTrue} userRole={userRole} />
                <div className="flex-1 bg-gray-850 p-10 overflow-auto">
                    {projectData && (
                        <h1 className="text-white text-base mb-8">
                            Project: {projectData.project_name}
                        </h1>
                    )}
                    <div>
                        {showMobileModal && (
                            <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-[1300]">
                                <div className="w-full max-w-md p-4 space-y-2 rounded-lg bg-gray-900 shadow-2xl z-[1300]">
                                    <button
                                        onClick={() => setShowMobileModal(false)}
                                        className="absolute top-0 right-0 mt-4 mr-4 text-gray-600 hover:text-gray-800 text-3xl p-2 z-[1300]"
                                    >
                                        &times;
                                    </button>
                                    <h2 className="text-center text-gray-200 text-lg z-[1300]">Scroll right for full data</h2>
                                    <p className="text-center text-gray-400 text-sm z-[1300]">
                                        The experience is currently ideally suited for larger screens, but scroll right for all the insight about your URLs.
                                    </p>
                                    <div className="flex justify-center mt-4">
                                        <button
                                            onClick={() => setShowMobileModal(false)}
                                            className="bg-blue-500 text-white px-6 py-2 rounded hover:bg-blue-600 text-sm z-[1300]"
                                        >
                                            Close
                                        </button>
                                    </div>
                                </div>
                            </div>
                        )}
                        {hasEnhancePlanUrls && (
                            <div className="w-full mb-4">
                                <CombinationChart />
                            </div>
                        )}
                        {hasEnhancePlanUrls ? (
                            <>
                                <div className="mt-[30px] z-[1000] min-w-[1200px]">
                                    <div className="mb-4 flex justify-between items-center text-sm z-[1000]">
                                        <div className="flex items-center z-[1000]">
                                            <SearchIcon className="cursor-pointer text-gray-200 z-[1000]" />
                                            <input
                                                type="text"
                                                placeholder="Search URLs"
                                                className="ml-2 text-sm appearance-none px-3 py-1 border border-gray-600 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 text-gray-200 bg-gray-900"
                                                value={searchTerm}
                                                onChange={(e) => setSearchTerm(e.target.value)}
                                            />
                                        </div>
                                        {!isPropertyIdMissing && hasEnhancePlanUrls && (
                                            <label className="flex items-center space-x-2 text-sm z-[1000]">
                                                <input
                                                    type="checkbox"
                                                    checked={showEnhancePlanUrlsOnly}
                                                    onChange={(e) => setShowEnhancePlanUrlsOnly(e.target.checked)}
                                                />
                                                <span>Show new opportunities for Enhance Plan</span>
                                            </label>
                                        )}
                                    </div>
                                </div>
                            </>
                        ) : (
                            <>
                                <h2 className="text-white text-base mb-2 text-center min-w-[1200px]">
                                    Step 6: Choose URLs to track in your Enhance Plan
                                </h2>
                                <p className="text-white text-sm mb-3 text-center min-w-[1200px]">
                                    If you connected your data sources, choose from our selected (up to) top 50 high traffic &amp; low engagement URLs. And/or add your own.
                                </p>
                                <div className="flex justify-center min-w-[1200px]">
                                    <button
                                        className="px-6 py-2 bg-blue-600 text-white text-sm rounded hover:bg-blue-900 flex items-center space-x-1"
                                        onClick={handleOpenModal}
                                    >
                                        <AddCircleIcon sx={{ color: '#ffffff', mr: 1 }} fontSize="small" /> Import your own URLs
                                    </button>
                                    <ImportUrlsModal
                                        isOpen={isModalOpen}
                                        onClose={handleCloseModal}
                                        projectId={projectId}
										propertyId={projectData?.propertyID}
                                        onUrlsSubmitted={setUrlsSubmittedToTrue}
                                        userRole={userRole}
                                    />
                                </div>
                                <div className="mb-4 flex justify-between items-center text-sm min-w-[1200px]">
                                    <div className="flex items-center z-[1000]">
                                        <SearchIcon className="cursor-pointer text-gray-200 z-[1000]" />
                                        <input
                                            type="text"
                                            placeholder="Search URLs"
                                            className="ml-2 text-sm appearance-none px-3 py-1 border border-gray-600 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 text-gray-200 bg-gray-900"
                                            value={searchTerm}
                                            onChange={(e) => setSearchTerm(e.target.value)}
                                        />
                                    </div>
                                    <label className="flex items-center space-x-2 z-[1000]">
                                        <input
                                            type="checkbox"
                                            checked={isSelectAllChecked}
                                            onChange={handleSelectAllChange}
                                        />
                                        <span>Select all</span>
                                    </label>
                                </div>
                            </>
                        )}
                        <ProjectTable
                            urls={urlsToShow}
                            projectId={projectId}
                            addToEnhancePlan={addToEnhancePlan}
                            selectedUrls={selectedUrls}
                            setSelectedUrls={setSelectedUrls}
                            urlsData={urlsData}
                            isPropertyIdMissing={isPropertyIdMissing}
                        />
                    </div>
                </div>
            </div>
            {/* Conditionally render the bottom bar if any checkbox is selected */}
            {isAnyCheckboxSelected && (
                <div className="fixed bottom-0 left-0 w-full py-2 px-4 bg-blue-900">
                    <div className="flex flex-col items-end w-full">
                        {enhancePlanUrlsCount === null && verifiedEmail === false ? (
                            <>
                                <div className="flex items-center mt-2">
                                    <div className="text-white text-sm mr-4">
                                        Verify your email address to continue
                                    </div>
                                    <button
                                        className="bg-white text-sm text-blue-900 py-2 px-4 rounded mr-4"
                                        onClick={resendVerificationEmail}
                                    >
                                        Re-send email
                                    </button>
                                </div>
                            </>
                        ) : (
                            <>
                                {!isPropertyIdMissing && projectData.enhance_plan_limit - enhancePlanUrlsCount - selectedUrls.size >= 0 && (
                                    <div className="text-white text-xs flex items-center mr-4 mt-2">
                                        <input
                                            type="checkbox"
                                            checked={isConsentChecked}
                                            onChange={(e) => setIsConsentChecked(e.target.checked)}
                                        />
                                        <label className="ml-2">
                                            I understand some basic information about these URLs will be shared with OpenAI as per our{' '}
                                            <a href="/privacy-policy#data-sharing" className="underline text-blue-400">
                                                privacy policy
                                            </a>.
                                        </label>
                                    </div>
                                )}
                                <div className="flex items-center mt-2">
                                    {projectData.issue_resolved ? (
                                        <>
                                            <div className="text-white text-sm mr-4">
                                                Remaining Enhance Plan URLs: {projectData.enhance_plan_limit - enhancePlanUrlsCount - selectedUrls.size}
                                            </div>
                                            {projectData.enhance_plan_limit - enhancePlanUrlsCount - selectedUrls.size < 0 ? (
                                                <button
                                                    className="bg-white text-sm text-blue-900 py-2 px-4 rounded mr-4"
                                                    onClick={() => {
                                                        window.location.href = 'https://www.contentenhance.com/pricing';
                                                    }}
                                                >
                                                    Upgrade
                                                </button>
                                            ) : (
                                                <button
                                                    className={`bg-white text-sm text-blue-900 py-2 px-4 rounded mr-4 ${(!isConsentChecked && !isPropertyIdMissing) ? "opacity-50 cursor-not-allowed" : ""}`}
                                                    onClick={handleSubmit}
                                                    disabled={!isConsentChecked && !isPropertyIdMissing}
                                                >
                                                    Submit URLs
                                                </button>
                                            )}
                                        </>
                                    ) : (
                                        <>
                                            <div className="text-white text-sm mr-4">
                                                Submission disabled until AI access issue is resolved. Please check your AI access{' '}
                                                <a href={accessCheckUrl}>here</a>.
                                            </div>
                                            <button
                                                className="bg-white text-sm text-blue-900 py-2 px-4 rounded mr-4 opacity-50 cursor-not-allowed"
                                                disabled={true}
                                            >
                                                Submit URLs
                                            </button>
                                        </>
                                    )}
                                </div>
                            </>
                        )}
                    </div>
                </div>
            )}
        </div>
    );
};

export default ProjectOverview;
