import React, { useEffect } from 'react';
import { Link } from 'react-router-dom'; // Import Link from react-router-dom
import TopBarMarketing from './TopBarMarketing';
import './HomePage.css'; // Import the custom CSS file
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import InsightsIcon from '@mui/icons-material/Insights';
import ChecklistIcon from '@mui/icons-material/Checklist';
import Pricing from './Pricing'; // Import the Pricing component
import Footer from './Footer'; // Import the Pricing component
import { Helmet } from 'react-helmet'; // Import Helmet from react-helmet

import DrawIcon from '@mui/icons-material/Draw'; //content manager
import LightbulbIcon from '@mui/icons-material/Lightbulb'; //content strategist
import PaidIcon from '@mui/icons-material/Paid'; //paid special
import TroubleshootIcon from '@mui/icons-material/Troubleshoot'; //SEO
import CampaignIcon from '@mui/icons-material/Campaign'; //PR
import TrendingUpIcon from '@mui/icons-material/TrendingUp'; //Digital marketer


import DatasetIcon from '@mui/icons-material/Dataset'; // connect data sources
import PeopleIcon from '@mui/icons-material/People'; // generate audience brief
import HelpCenterIcon from '@mui/icons-material/HelpCenter';
import LinkIcon from '@mui/icons-material/Link';
import contentPerformanceImage from './images/content-performance.png';
import changePointDetectionImage from './images/change-point-detection.png';
import qualityImage from './images/quality-content.png';
import journeyFlow from './images/journey-flow.png';


const HomePage = () => {
	const scrollToHash = () => {
	  const hash = window.location.hash;
	  if (hash) {
	    setTimeout(() => {
	      const element = document.querySelector(hash);
	      if (element) {
	        element.scrollIntoView({ behavior: 'smooth' });
	      }
	    }, 200); // Adjust delay as needed
	  }
	};

    // Trigger `scrollToHash` when the hash in the URL changes
    useEffect(() => {
      scrollToHash();
    }, [window.location.hash]);
	
  const images = {
    image0: require('./images/product-overview.gif'),
    image1: require('./images/choose-urls-gif.gif'),
    image2: require('./images/ai-generate-reccs.gif'),
    image3: require('./images/generate-support.gif'),
    image4: require('./images/dashboard-screenshot.png'),
    image5: require('./images/task-date.gif'),
    image6: require('./images/wayback-machine-contentenhance.gif'),
	image7: require('./images/user-interactions.png'),
	image8: require('./images/import-urls.png'),
	image9: require('./images/audience-brief.gif'),
	image10: require('./images/task-manager-static.png'),
	 image11: require('./images/redirect-detect.png'),
	  image12: require('./images/refreshes.gif'),
	  image13: require('./images/cookie-manager.png'),
    placeholder: require('./images/wayback-machine-contentenhance.gif'),
  };

  const resourceData = [
    {
      title: 'Measuring Content Performance in 2025',
      category: 'Guides',
      excerpt: 'Learn how to measure and optimize your content performance using Pettitt\'s test and engagement metrics.',
      authors: ['Richard Lawrence'],
      link: '/content-performance',
      imageUrl: contentPerformanceImage
    },
    {
      title: 'Change Point Detection: A/B Testing Alternative',
      category: 'Guides',
      excerpt: 'Explore Pettitt\'s test as a method to detect significant changes in your web metrics.',
      authors: ['Richard Lawrence'],
      link: '/change-point-detection',
      imageUrl: changePointDetectionImage
    },
    {
      title: 'Using AI to Quality Check Your Content',
      category: 'Guides',
      excerpt: 'Find out how to use AI vision to assess content - or let contentEhnace do it for you.',
      authors: ['Richard Lawrence'],
      link: '/ai-content-quality-check',
      imageUrl: qualityImage
    }
  ];

  return (
    <div className="flex flex-col min-h-screen text-black">
      <Helmet>
        <title>contentEnhance - Content Analytics & Optimization Tool</title>
        <meta name="description" content="Add rocket fuel to your content optimization with contentEnhance." />
      <meta property="og:title" content="contentEnhance - Content Analytics & Optimization Tool" />
      <meta property="og:description" content="Add rocket fuel to your content optimization with contentEnhance." />
      <meta property="og:image" content={images.image0} />
      <meta property="og:url" content="https://www.contentenhance.com" />
      <meta property="og:type" content="website" />
      <meta property="og:site_name" content="contentEnhance" />

      {/* Twitter Card meta tags */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content="contentEnhance - Content Analytics & Optimization Tool" />
      <meta name="twitter:description" content="Add rocket fuel to your content optimization with contentEnhance." />
      <meta name="twitter:image" content={images.image0} />
      <meta name="twitter:site" content="@contentEnhance" />
      </Helmet>
      
      <TopBarMarketing />
      <section className="hero-section flex flex-col justify-center items-center text-center">
        <h1 className="text-4xl mb-6 px-2 text-white">Add rocket fuel to your content optimization</h1>
        <Link to="/signup" className="button-link">Get started for free</Link>
      </section>
      <div className="container mt-6">
      
        <section id="what-contentEnhance" className="breakout-block">
          <div className="why-section">
            <div className="why-block">
              <div className="why-text">
                <h2 className="text-4xl mb-6">What is contentEnhance?</h2>
                <p className="mb-2">Helping you to <strong>put content first</strong> and <strong>future-proof your SEO</strong>, contentEnhance is a tool that helps you uncover <strong>in-demand content</strong> on your website that is <strong>letting your users down</strong> in terms of <strong>engagement</strong> and <strong>user journey</strong>.</p> 
	  <p className="mb-4">It then uses AI that is <strong>briefed with your audience insight</strong> to inspire you how to improve that content <strong>at scale</strong>. And it helps you track <strong>outputs</strong> and <strong>outcomes</strong> as you make the improvements.</p>
	  <a href="#pricing" className="button-link">See pricing</a>
              </div>
              <div className="why-image"><img src={images.image0} loading="lazy"  /></div>
            </div>
          </div>
        </section>
      
        <section id="why-contentEnhance">
          <h2 className="text-4xl mb-10 mt-6 text-center">Why contentEnhance?</h2>
          <div className="why-section">
            {[
              { heading: 'Quickly find your biggest opportunities to improve', text: 'We flag pages with high traffic but low engagement so that you can have the biggest impact from your enhance activities. This also includes important top level information about the page, such top traffic source, device & search queries, to help with your decision-making.', image: images.image1 },
              { heading: 'Take an audience-first approach at scale', text: 'We help you create an audience brief to assess your content, then we assess each page programmatically - just as the user would see it. This results in a number of relevant ideas for you to choose from you enhance the experience of your audiences.', image: images.image2 },
              { heading: 'Get specific support for actioning recommendations', text: 'Each idea for improvement comes with a deeper dive into how and what to do - available on instant demand - further accelerating your work.', image: images.image3 },
              { heading: 'Make it easier to self serve data again', text: 'Let\'s face it - GA4 is complicated and difficult for content teams to use. We pull out the main metrics you need to know about such as where users come from, where they go and how long they generally spend on the page and present them in an easy to digest format.', image: images.image4 },
              { heading: 'Tie activities to tangible results', text: 'As you tick content improvement activities off in our built-in task manager - each task populated by the AI idea generator, but editable with the ability to add your own ideas - they are plotted on the graph so you can immediately see their impact over time.', image: images.image5 },
              { heading: 'Retain the history of your content in one place', text: 'With your own personal Wayback Machine, taking a screenshot of each page every day, you have a central repository showing how your content changes over time - forever. You can then compare various dates to see what may have caused performance changes.', image: images.image6 },
            ].map((item, index) => (
              <div className={`why-block ${index % 2 === 0 ? 'flex-row-reverse' : ''}`} key={index}>
                <div className="why-text">
                  <h3 className="why-heading">{item.heading}</h3>
                  <p>{item.text}</p>
                </div>
                <div className="why-image"><img src={item.image} alt={item.heading} loading="lazy" /></div>
              </div>
            ))}
          </div>
        </section>
      
<section id="who-contentenhance">
  <h2 className="text-4xl mb-4 mt-16 text-center">Who's it for?</h2>
  <section className="features-section grid grid-cols-1 md:grid-cols-2 gap-8">
    <div className="feature">
      <LightbulbIcon style={{ fontSize: '4rem' }} className="mx-auto mb-4 text-blue-500" />
      <h3 className="text-2xl mb-2">Content strategists</h3>
      <p className="mb-2">By analyzing audience reactions and leveraging AI for audience-informed content improvements with contentEnhance, you can ensure content <strong>aligns with user needs</strong>.</p> 
		<p>Tracking the effectiveness of these enhancements through key performance metrics supports a <strong>data-driven approach</strong> to refining and improving the overall content strategy continuously.</p>
    </div>
    <div className="feature">
      <CampaignIcon style={{ fontSize: '4rem' }} className="mx-auto mb-4 text-blue-500" />
      <h3 className="text-2xl mb-2">Digital PRs</h3>
      <p className="mb-2"><strong>Striving to prove the value of your campaigns beyond links?</strong></p> 
		<p className="mb-2">contentEnhance's journey mapper tracks the added value of the links you achieve in terms of <strong>traffic they refer to your campaign content</strong>, plus how effective your campaign pages are at <strong>driving traffic to areas of the site that fit your business objectives</strong>.</p> 
		<p>As well as inspiring you how to improve them.</p>
    </div>
    <div className="feature">
      <TroubleshootIcon style={{ fontSize: '4rem' }} className="mx-auto mb-4 text-blue-500" />
      <h3 className="text-2xl mb-2">SEOs</h3>
      <p className="mb-2">Content needs to emit signals that <strong>users find it helpful</strong> in order to rank highly in search engines in 2024 <strong>and beyond</strong>. </p>
		<p>Tracking metrics such as <strong>content engagement rate</strong> and <strong>content engagement time</strong> can provide a vital, trackable proxy for this, whilst the <strong>audience-informed</strong> ideas to improve can provide a very useful addition for a content audit with the aim of increased visibility.</p>
    </div>
    <div className="feature">
      <DrawIcon style={{ fontSize: '4rem' }} className="mx-auto mb-4 text-blue-500" />
      <h3 className="text-2xl mb-2">Content managers</h3>
      <p className="mb-2">By monitoring important content and audience reactions to that content, you can keep track of <a href="https://www.contentenhance.com/content-performance">content performance</a> and make <strong>informed adjustments</strong>.</p> 
	<p>The AI-driven suggestions enable you to implement improvements <strong>efficiently</strong> and <strong>at scale</strong>, ensuring consistency in content quality. The personal Wayback Machine is also a god send for keeping records.</p>
    </div>
    <div className="feature">
      <TrendingUpIcon style={{ fontSize: '4rem' }} className="mx-auto mb-4 text-blue-500" />
      <h3 className="text-2xl mb-2">Digital marketers</h3>
      <p className="mb-2">contentEnhance provides a <strong>cross-channel view</strong> into the performance of your content, helping you to identify channels that send <strong>high quality, relevant traffic</strong>, plus content that needs more focus to help you achieve your business objectives.</p> 
	<p>Then it helps you improve it with the use of an <strong>informed audience brief.</strong> </p>
    </div>
    <div className="feature">
      <PaidIcon style={{ fontSize: '4rem' }} className="mx-auto mb-4 text-blue-500" />
      <h3 className="text-2xl mb-2">Paid ad specialists</h3>
      <p className="mb-2">Quickly identify landing pages that have <strong>poor engagement metrics</strong> for your paid advertisement campaigns and either implement the <strong>audience-informed ideas</strong> to improve, or <strong>refine your campaigns</strong> as a result.</p> 
	  <p>Then <strong>track the progress</strong> as you implemented the changes.</p>
    </div>
  </section>
</section>
			
		<section id="seo-approach" className="breakout-block mb-6">
		  <h2 className="text-4xl mb-2 text-center mt-6">A modern approach to SEO</h2>
		  <div className="why-section">
		    {[
		      {
		        heading: 'Show search engines that users love your content',
		        text: 'Google has long since used signals such as how often users return to the search results from a page to determine how useful it is. By improving metrics like engagement time and engagement time you will not only be more useful to your users, but also giving the right signals to search engines, resulting in better SEO performance.',
		        image: images.image7,
		      },
		    ].map((item, index) => (
		      <div className={`why-block ${index % 2 === 0 ? 'flex-row-reverse' : ''}`} key={index}>
		        <div className="why-text">
		          <h3 className="why-heading">{item.heading}</h3>
		          <p>{item.text}</p>
		          <div className="onward-journey mt-2">
		            <a className="button-link" href="https://searchengineland.com/google-search-ranking-documents-434141" target="_blank" rel="noopener noreferrer">
		              Read more
		            </a>
		          </div>
		        </div>
		        <div className="why-image">
		          <img src={item.image} alt={item.heading} loading="lazy" />
		        </div>
		      </div>
		    ))}
		  </div>
		</section>


      
        <section id="packed-features">
          <h2 className="text-4xl mb-6 text-center">Packed with useful features</h2>
          <div className="features-section">
            {[
              { heading: 'URL prioritization', text: 'Find URLs that have higher than average traffic and lower than average engagement', image: images.image1 },
              { heading: 'URL import', text: 'Easily import URLs to analyze their performance and get actionable insights.', image: images.image8 },
              { heading: 'Audience briefing with AI support', text: 'Create an audience brief to improve your AI recommendations - and AI will also help you create the audience brief.', image: images.image9 },
              { heading: 'AI generated optimization ideas', text: 'Receive a number of ideas about improvements to make to your content to improve audience engagement.', image: images.image2 },
              { heading: 'AI generated implementation support', text: 'Generate additional support for implementing the optimization ideas - for example writing the intro or some FAQs.', image: images.image3 },
              { heading: 'URL user journey mapper', text: 'See which sources or internal pages have brough traffic to your content - and then where it goes next.', image: images.image4 },
				{ heading: 'Task impact graphs', text: 'When you add launch dates to your tasks, they are automatically plotted on your engagement graphs so you can see which tasks had an impact.', image: images.image5 },
              { heading: 'Task manager', text: 'AI generated ideas are automatically ported into a task manager, so you can then allocate to team members, edit or add your own.', image: images.image10 },
              { heading: 'Personal wayback machine', text: 'A screenshot is taken and saved every day for your chosen URLs, which you can then easily navigate with a calendar layout.', image: images.image6 },
              { heading: 'Redirect detection', text: 'Redirects are automatically detected and flagged to help with your decision-making.', image: images.image11 },
              { heading: 'URL ideas refresh', text: 'Get fresh ideas for optimizing your URLs on demand, to ensure they are up-to-date for your content\'s current state.', image: images.image12 },
              { heading: 'Cookie manager (beta)', text: 'Add cookies for inclusion when the AI agent is taking the screenshot of your URLs, to get behind logins or circumnavigate geotargeting.', image: images.image13 },
            ].map((item, index) => (
              <div className="feature-block flex flex-col md:flex-row mb-8" key={index}>
                <div className="feature-text md:w-1/2">
                  <h3 className="text-2xl mb-4">{item.heading}</h3>
                  <p className="text-md mb-2 p-2">{item.text}</p>
                </div>
                <div className="feature-image md:w-1/2">
                  <img src={item.image} alt={item.heading} loading="lazy" />
                </div>
              </div>
            ))}
          </div>
        </section>
      
        
      
        <section id="how-it-works">
          <h2 className="text-4xl mb-6 text-center">How it works</h2>
          <section className="features-section grid grid-cols-1 md:grid-cols-2 gap-8">
            <div className="feature">
              <DatasetIcon style={{ fontSize: '4rem' }} className="mx-auto mb-4 text-blue-500" />
              <h3 className="text-2xl mb-2">1. Connect data sources</h3>
              <p className="text-sm">Connect your Google Analytics & Google Search Console accounts to identify pages that are attracting high volumes of traffic with low engagement metrics.</p>
            </div>
            <div className="feature">
              <PeopleIcon style={{ fontSize: '4rem' }} className="mx-auto mb-4 text-blue-500" />
              <h3 className="text-2xl mb-2">2. Generate or input audience brief</h3>
              <p className="text-sm">Provide an audience brief to use powerful AI models to generate potential ideas to improve your pages to better fulfill your audience's needs.</p>
            </div>
            <div className="feature">
              <HelpCenterIcon style={{ fontSize: '4rem' }} className="mx-auto mb-4 text-blue-500"  />
              <h3 className="text-2xl mb-2">3. Confirm AI access</h3>
              <p className="text-sm">Confirm that our AI agenct can access your website and add our IP to a whitelist if not.</p>
            </div>
            <div className="feature">
              <LinkIcon style={{ fontSize: '4rem' }} className="mx-auto mb-4 text-blue-500" />
              <h3 className="text-2xl mb-2">4. Choose URLs to track</h3>
              <p className="text-sm">Choose which URLs to add to your Enhance Plan 0either from those we recommend or simply add your own.</p>
            </div>
          </section>
        </section>
			
	  
			
			
      
        <section id="pricing" className="pricing-section">
          <Pricing />
        </section>
	  <section id="latest-resources">
	    <h2 className="text-4xl mb-10 mt-10 text-center">Latest resources</h2>
	    <div className="container mx-auto px-4 py-10">
	      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 mt-4 mb-16">
	        {resourceData.map((resource, index) => (
	          <div
	            key={index}
	            className="border rounded-lg shadow-sm overflow-hidden flex flex-col"
	          >
	            {resource.imageUrl && (
	              <img
	                src={resource.imageUrl}
	                alt={resource.title}
	                className="w-full h-48 object-cover"
	                loading="lazy"
	              />
	            )}
	            <div className="p-4 flex flex-col flex-grow">
	              <p className="text-sm text-gray-500 mb-1">{resource.category}</p>
	              <h3 className="text-lg font-semibold mb-2">
	                <a href={resource.link}>{resource.title}</a>
	              </h3>
	              <p className="text-gray-600 flex-grow">{resource.excerpt}</p>
	              {resource.authors && resource.authors.length > 0 && (
	                <p className="text-sm text-gray-500 mt-4">
	                  {resource.authors.join(', ')}
	                </p>
	              )}
	              <a
	                href={resource.link}
	                className="text-sm mt-4 inline-block bg-blue-600 text-white py-2 px-4 rounded hover:bg-blue-800 transition-colors"
	              >
	                Read more
	              </a>
	            </div>
	          </div>
	        ))}
	      </div>
	    </div>
	  </section>
		
      </div>
			<Footer />
    </div>
  );
};

export default HomePage;
