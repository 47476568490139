import React, { useState, useEffect } from 'react';
import { useCookies } from 'react-cookie';

const CookieConsent = () => {
  // Compute the headless flag at the very beginning.
  const isHeadless =
    typeof navigator !== 'undefined' &&
    navigator.userAgent.includes('ReactSnap');

  // Always call hooks unconditionally.
  const [cookies, setCookie] = useCookies(['consent']);
  const [showConsent, setShowConsent] = useState(false);

  useEffect(() => {
    // Only update state if not prerendering.
    if (!isHeadless && !cookies.consent) {
      setShowConsent(true);
    }
  }, [cookies.consent, isHeadless]);

  // Now conditionally render.
  if (isHeadless) {
    return null;
  }

  if (!showConsent) {
    return null;
  }

  return (
    <div className='fixed bottom-0 left-1/2 w-full sm:w-3/4 md:w-1/2 lg:w-1/3 xl:w-1/3 max-w-2xl min-w-xs py-15 p-8 bg-blue-500 text-white flex flex-col items-center justify-center transform -translate-x-1/2 z-[5000]'>
      <p>
        We use some <strong>standard analytics packages</strong> to understand user behavior, so we 
        can figure out how to improve our content. This involves some cookies. Are you OK with this?
      </p>
      <div className="flex mt-2">
        <button
          onClick={() => {
            setShowConsent(false);
            setCookie('consent', 'true', { path: '/' });
            window.dispatchEvent(new Event('updateGTMConsent'));
          }}
          className='bg-white text-blue-500 px-4 py-2 rounded mr-2'
        >
          Accept
        </button>
        <button
          onClick={() => {
            setShowConsent(false);
            // Optionally, set a "declined" cookie if desired.
          }}
          className='bg-white text-blue-500 px-4 py-2 rounded'
        >
          Decline
        </button>
      </div>
    </div>
  );
};

export default CookieConsent;
