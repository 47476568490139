import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Sidebar from './Sidebar';
import TopBar from './TopBar';
import { useNavigate } from 'react-router-dom';

const UserSettings = () => {
    const navigate = useNavigate();
    const [errorMessage, setErrorMessage] = useState('');
    const [userName, setUserName] = useState('');
    const [emailAddress, setEmailAddress] = useState('');
    const [currentPassword, setCurrentPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [userRole, setUserRole] = useState('');
    const [userId, setUserId] = useState('');  // Add userId state
    const [isSSOUser, setIsSSOUser] = useState(false);
    const [plan, setPlan] = useState('');
    const [refreshDate, setRefreshDate] = useState('');
    const [generatesLeft, setGeneratesLeft] = useState(0);
    const [refreshesLeft, setRefreshesLeft] = useState(0);
    const [swapsLeft, setSwapsLeft] = useState(0);

    useEffect(() => {
        const checkAuthentication = async () => {
            try {
                const response = await axios.get('https://api.contentenhance.com/api/auth/check', { withCredentials: true });
                if (!response.data.isAuthenticated) {
                    navigate('/login');
                }
            } catch (error) {
                console.error('Authentication check failed:', error);
                navigate('/login');
            }
        };

        const fetchUserDetails = async () => {
            try {
                const response = await axios.get('https://api.contentenhance.com/user', { withCredentials: true });
                if (response.data) {
                    const defaultUsername = response.data.email ? response.data.email.split('@')[0] : '';
                    setUserName(response.data.user_name || defaultUsername);
                    setEmailAddress(response.data.email);
                    setUserRole(response.data.role);
                    setUserId(response.data.user_id); // Set the userId
                    setIsSSOUser(response.data.avatar_url && response.data.avatar_url.includes('googleusercontent'));

                    // Store plan details
                    setPlan(response.data.plan);
                    setGeneratesLeft(response.data.number_of_generates);
                    setRefreshesLeft(response.data.number_of_refreshes);
                    setSwapsLeft(response.data.number_of_swaps);

                    // Calculate and set refresh/expiry date based on the plan
                    const updatedDate = new Date(response.data.personal_plan_updated);

                    if (response.data.plan === 'individual') {
                        const nextRefreshDate = new Date(updatedDate);
                        nextRefreshDate.setDate(updatedDate.getDate() + 30);
                        setRefreshDate(nextRefreshDate.toISOString().split('T')[0]);
                    } else if (response.data.plan === 'free') {
                        const expiryDate = new Date(updatedDate);
                        expiryDate.setDate(updatedDate.getDate() + 7);
                        const currentDate = new Date();

                        if (currentDate > expiryDate) {
                            setRefreshDate('Lapsed');
                        } else {
                            setRefreshDate(expiryDate.toISOString().split('T')[0]);
                        }
                    }
                }
            } catch (error) {
                console.error('Failed to fetch user details:', error);
                setErrorMessage('Failed to load user details');
            }
        };

        checkAuthentication();
        fetchUserDetails();
    }, [navigate]);

    const handleNameChange = (event) => {
        setUserName(event.target.value);
    };

    const handleEmailChange = (event) => {
        setEmailAddress(event.target.value);
    };

    const handleCurrentPassword = (event) => {
        setCurrentPassword(event.target.value);
    };

    const handleNewPassword = (event) => {
        setNewPassword(event.target.value);
    };

    const handlePasswordChange = async () => {
        if (isSSOUser) {
            alert('Password management is handled through your SSO provider.');
            return;
        }

        try {
            const response = await axios.post(`https://api.contentenhance.com/change_password`, {
                current_password: currentPassword,
                new_password: newPassword
            }, { withCredentials: true });

            if (response.status === 200) {
                alert('Password changed successfully');
                setCurrentPassword('');
                setNewPassword('');
            } else {
                throw new Error(response.data.error || 'Failed to change password');
            }
        } catch (error) {
            console.error('Password change error:', error.response?.data?.error || error.message);
            alert(error.response?.data?.error || 'An error occurred while changing the password');
        }
    };

    const handleSubmit = async () => {
        try {
            const response = await axios.post(`https://api.contentenhance.com/update_user`, {
                name: userName,
                email: emailAddress
            }, { withCredentials: true });

            if (response.status === 200) {
                alert('User details updated successfully');
            } else {
                throw new Error('Failed to update details');
            }
        } catch (error) {
            console.error('Update error:', error.message);
            alert('Failed to update user details');
        }
    };

    const handleDeleteAccount = async () => {
        const isConfirmed = window.confirm("Are you sure you want to delete your account? This action cannot be undone.");
        if (isConfirmed) {
            try {
                const response = await axios.delete(`https://api.contentenhance.com/delete_user`, { withCredentials: true });
                if (response.status === 200) {
                    alert('Account deleted successfully');
                    localStorage.clear();
                    sessionStorage.clear();
                    navigate('/login');
                } else {
                    throw new Error('Failed to delete the account');
                }
            } catch (error) {
                console.error('Error:', error.message);
                alert('An error occurred while deleting the account');
            }
        }
    };

    return (
        <div className="flex flex-col h-screen bg-darkBlue">
            <TopBar />
            <div className="flex h-screen bg-lighterDarkBlue text-white">
                <Sidebar />
                <div className="flex-1 bg-gray-850 p-10 overflow-auto">
                    <h1 className="text-white text-base mb-8">User settings</h1>

                    <div className="bg-gray-900 mb-8 p-4">
                        <h2 className="text-white text-base mb-2">Plan details</h2>
                        <p className="text-white text-sm">Plan type: {plan}</p>
                        <p className="text-white text-sm">
                            {plan === 'individual' ? 'Next Refresh Date' : 'Plan Expiry Date'}: {refreshDate}
                        </p>
                        <p className="text-white text-sm">Generates left: {generatesLeft}</p>
                        <p className="text-white text-sm">Refreshes left: {refreshesLeft}</p>
                        <p className="text-white text-sm mb-2">Swaps left: {swapsLeft}</p>
                        <button className="mt-4 bg-red-600 hover:bg-red-700 text-white py-2 px-4 rounded text-sm" onClick={() => { window.location.href = 'https://www.contentenhance.com/pricing'; }}>
                            Upgrade
                        </button>
                    </div>

                    <div className="bg-gray-900 mb-8 p-4">
                        <h2 className="text-white text-base mb-2">Update details</h2>
                        <p className="text-white text-sm mb-2">You can only change your email address if you have not used your Google account to sign in.</p>
                        <p className="text-white text-sm mb-2">Name</p>
                        <input
                            type="text"
                            value={userName}
                            onChange={handleNameChange}
                            className="text-sm appearance-none px-3 py-1 border border-gray-600 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 text-gray-200 bg-gray-900 h-[35px] mb-2"
                        />
                        <button onClick={handleSubmit} className="p-2 rounded text-sm h-[35px] text-white bg-blue-500 hover:bg-blue-900 ml-2">
                            Save changes
                        </button>

                        <p className="text-white text-sm mb-2">Email</p>
                        <input
                            type="text"
                            value={emailAddress}
                            onChange={handleEmailChange}
                            disabled={isSSOUser}
                            onClick={() => isSSOUser && alert("Your email cannot be changed here. Please use your SSO provider.")}
                            className="text-sm appearance-none px-3 py-1 border border-gray-600 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 text-gray-200 bg-gray-900 h-[35px] mb-2"
                        />
                        <button disabled={isSSOUser} onClick={handleSubmit} className="p-2 rounded text-sm h-[35px] text-white bg-blue-500 hover:bg-blue-900 ml-2">
                            Save changes
                        </button>
                    </div>

                    <div className="bg-gray-900 mb-8 p-4">
                        <h2 className="text-white text-base mb-2">Change password</h2>
                        <p className="text-white text-sm mb-2">You can only change your password if you have not used your Google account to sign in.</p>
                        <p className="text-white text-sm mb-2">Enter current password</p>
                        <input
                            type="password"
                            value={currentPassword}
                            onChange={handleCurrentPassword}
                            disabled={isSSOUser}
                            onClick={() => isSSOUser && alert("Password management is handled through your SSO provider.")}
                            className="text-sm appearance-none px-3 mb-2 py-1 border border-gray-600 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 text-gray-200 bg-gray-900 h-[35px]"
                        />
                        <p className="text-white text-sm mb-2">Enter new password</p>
                        <input
                            type="password"
                            value={newPassword}
                            onChange={handleNewPassword}
                            disabled={isSSOUser}
                            className="text-sm appearance-none px-3 mb-2 py-1 border border-gray-600 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 text-gray-200 bg-gray-900 h-[35px]"
                        />
                        <button onClick={handlePasswordChange} className="p-2 rounded text-sm h-[35px] text-white bg-blue-500 hover:bg-blue-900 ml-2">
                            Save changes
                        </button>
                    </div>

                    <div className="bg-gray-900 mb-8 p-4">
                        <h2 className="text-white text-base mb-2">Delete account</h2>
                        <p className="text-white text-sm mb-2">Deleting your account will delete all projects associated with your account where you are the only user. If other users are associated with the project, one will be randomly assigned as an admin.</p>
                        <button className="mt-4 bg-red-600 hover:bg-red-700 text-white py-2 px-4 rounded text-sm" onClick={handleDeleteAccount}>
                            Delete
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default UserSettings;
