import React from 'react';
import { Link } from 'react-router-dom';
import TopBarMarketing from './TopBarMarketing';
import './ContentPerformance.css'; // Reuse the same CSS as in ContentPerformance
import Pricing from './Pricing';
import Footer from './Footer';
import { Helmet } from 'react-helmet';
import ExampleBarChartComponent from './ExampleBarChartComponent';

// (Optional) If you want to show a Sankey or "journey" style diagram, import it:
// import SankeyDiagramExampleComponent from './SankeyDiagramExampleComponent';

const ChangePointDetection = () => {
  // Dummy data for engagement metrics
  const dummyEngagementData = [
    { date: '2024-10-01', engagement_time: 60, sessions: 100, engagement_rate: 0.8 },
    { date: '2024-10-02', engagement_time: 75, sessions: 120, engagement_rate: 0.85 },
    { date: '2024-10-03', engagement_time: 50, sessions: 90, engagement_rate: 0.75 },
    { date: '2024-10-04', engagement_time: 65, sessions: 110, engagement_rate: 0.82 },
    { date: '2024-10-05', engagement_time: 80, sessions: 130, engagement_rate: 0.88 },
    { date: '2024-10-06', engagement_time: 55, sessions: 95, engagement_rate: 0.78 },
    { date: '2024-10-07', engagement_time: 70, sessions: 115, engagement_rate: 0.84 },
    { date: '2024-10-08', engagement_time: 68, sessions: 105, engagement_rate: 0.81 },
    { date: '2024-10-09', engagement_time: 72, sessions: 125, engagement_rate: 0.86 },
    { date: '2024-10-10', engagement_time: 58, sessions: 102, engagement_rate: 0.79 },
    { date: '2024-10-11', engagement_time: 63, sessions: 107, engagement_rate: 0.83 },
    { date: '2024-10-12', engagement_time: 77, sessions: 135, engagement_rate: 0.89 },
    { date: '2024-10-13', engagement_time: 62, sessions: 112, engagement_rate: 0.8 },
    { date: '2024-10-14', engagement_time: 55, sessions: 100, engagement_rate: 0.76 },
    { date: '2024-10-15', engagement_time: 66, sessions: 118, engagement_rate: 0.85 },
    { date: '2024-10-16', engagement_time: 73, sessions: 127, engagement_rate: 0.87 },
    { date: '2024-10-17', engagement_time: 59, sessions: 109, engagement_rate: 0.8 },
    { date: '2024-10-18', engagement_time: 64, sessions: 120, engagement_rate: 0.84 },
    { date: '2024-10-19', engagement_time: 78, sessions: 138, engagement_rate: 0.9 },
    { date: '2024-10-20', engagement_time: 67, sessions: 115, engagement_rate: 0.86 },
    { date: '2024-10-21', engagement_time: 70, sessions: 130, engagement_rate: 0.88 },
    { date: '2024-10-22', engagement_time: 60, sessions: 105, engagement_rate: 0.81 },
    { date: '2024-10-23', engagement_time: 74, sessions: 133, engagement_rate: 0.89 },
    { date: '2024-10-24', engagement_time: 65, sessions: 118, engagement_rate: 0.82 },
    { date: '2024-10-25', engagement_time: 69, sessions: 125, engagement_rate: 0.87 },
    { date: '2024-10-26', engagement_time: 56, sessions: 102, engagement_rate: 0.78 },
    { date: '2024-10-27', engagement_time: 61, sessions: 112, engagement_rate: 0.8 },
    { date: '2024-10-28', engagement_time: 75, sessions: 130, engagement_rate: 0.88 },
    { date: '2024-10-29', engagement_time: 58, sessions: 99, engagement_rate: 0.79 },
    { date: '2024-10-30', engagement_time: 68, sessions: 122, engagement_rate: 0.85 },
  ];

  // Dummy data with a step change for demonstration of Pettitt's test
  const dummyEngagementDataStepChange = [
    { date: '2024-10-01', engagement_time: 55, sessions: 100, engagement_rate: 0.78 },
    { date: '2024-10-02', engagement_time: 60, sessions: 120, engagement_rate: 0.80 },
    { date: '2024-10-03', engagement_time: 52, sessions: 90, engagement_rate: 0.75 },
    { date: '2024-10-04', engagement_time: 58, sessions: 110, engagement_rate: 0.79 },
    { date: '2024-10-05', engagement_time: 63, sessions: 130, engagement_rate: 0.82 },
    { date: '2024-10-06', engagement_time: 50, sessions: 95, engagement_rate: 0.76 },
    { date: '2024-10-07', engagement_time: 57, sessions: 115, engagement_rate: 0.78 },
    { date: '2024-10-08', engagement_time: 54, sessions: 105, engagement_rate: 0.77 },
    { date: '2024-10-09', engagement_time: 59, sessions: 125, engagement_rate: 0.81 },
    { date: '2024-10-10', engagement_time: 53, sessions: 102, engagement_rate: 0.76 },
    { date: '2024-10-11', engagement_time: 55, sessions: 107, engagement_rate: 0.78 },
    { date: '2024-10-12', engagement_time: 62, sessions: 135, engagement_rate: 0.83 },
    { date: '2024-10-13', engagement_time: 57, sessions: 112, engagement_rate: 0.79 },
    { date: '2024-10-14', engagement_time: 52, sessions: 100, engagement_rate: 0.76 },
    { date: '2024-10-15', engagement_time: 60, sessions: 118, engagement_rate: 0.80 },
    // Step change occurs here
    { date: '2024-10-16', engagement_time: 80, sessions: 127, engagement_rate: 0.88 },
    { date: '2024-10-17', engagement_time: 78, sessions: 109, engagement_rate: 0.86 },
    { date: '2024-10-18', engagement_time: 83, sessions: 120, engagement_rate: 0.89 },
    { date: '2024-10-19', engagement_time: 85, sessions: 138, engagement_rate: 0.9 },
    { date: '2024-10-20', engagement_time: 82, sessions: 115, engagement_rate: 0.88 },
    { date: '2024-10-21', engagement_time: 87, sessions: 130, engagement_rate: 0.91 },
    { date: '2024-10-22', engagement_time: 79, sessions: 105, engagement_rate: 0.87 },
    { date: '2024-10-23', engagement_time: 85, sessions: 133, engagement_rate: 0.92 },
    { date: '2024-10-24', engagement_time: 81, sessions: 118, engagement_rate: 0.88 },
    { date: '2024-10-25', engagement_time: 83, sessions: 125, engagement_rate: 0.9 },
    { date: '2024-10-26', engagement_time: 76, sessions: 102, engagement_rate: 0.85 },
    { date: '2024-10-27', engagement_time: 80, sessions: 112, engagement_rate: 0.87 },
    { date: '2024-10-28', engagement_time: 88, sessions: 130, engagement_rate: 0.92 },
    { date: '2024-10-29', engagement_time: 74, sessions: 99, engagement_rate: 0.84 },
    { date: '2024-10-30', engagement_time: 82, sessions: 122, engagement_rate: 0.89 },
  ];

  return (
    <div className="flex flex-col min-h-screen text-black">
      <Helmet>
        <title>Change Point Detection with contentEnhance</title>
        <meta
          name="description"
          content="Learn how to use Pettitt's test for change point detection with contentEnhance to analyze web page engagement metrics."
        />
      </Helmet>

      <TopBarMarketing />

       <div className="container mt-12">
        {/* HERO / CTA Section with Pettitt's Test Example */}
        <section id="cpd-introduction" className="breakout-block">
          <div className="why-section">
            <div className="why-block">
              <div className="why-text">
                <p className="text-sm text-gray-500 mb-6 mt-12">LEVEL UP YOUR STATISTICAL ANALYSIS</p>
                <h1 className="text-6xl font-bold mb-6">
                  An Alternative to A/B Testing: Change Point Detection
                </h1>
                <p className="mb-6">
                  What happens when you can't run an A/B test on a change to your content?
                  Maybe you don't have the traffic to run a proper A/B test, or you can only
                  make changes to a single version of a page. In these cases, change point
                  detection using Pettitt's test can be a useful alternative. 
                  <strong> contentEnhance</strong> makes it easy to apply Pettitt's test
                  to detect significant changes in engagement metrics over time, 
                  without the need for A/B testing.
                </p>
                <Link to="/signup" className="button-link">
                  Sign up free
                </Link>
              </div>

              {/* Hero Chart Example */}
<div className="bg-darkBlue w-full sm:w-[700px] mt-6 h-[550px] sm:h-[400px] flex justify-center items-center px-4">
          
                <ExampleBarChartComponent
                  engagementData={dummyEngagementDataStepChange}
                  completedTasks={[]} // Replace with actual tasks if any
                />
              </div>
            </div>
          </div>
        </section>
	          {/* Content Performance Section */}
	          <section id="pettits-test" className="breakout-block">
	            <div className="performance-section">
	              <div className="performance-text">
			  <h2 className="text-3xl font-bold mb-6 mt-6">Why Change Point Detection Might be Right for You</h2>
			   <p className="mb-6">
			  While A/B testing is the gold standard for measuring the impact of changes, it’s not always practical. Maybe your site doesn’t have enough traffic to generate statistically significant results, or perhaps you can only modify a single version of a page without the ability to serve different experiences to different users. In these cases, change point detection can provide a data-driven alternative. By analyzing historical engagement trends, Pettitt’s test can help identify when a significant shift occurred, allowing you to infer whether a content change had an impact—even without running a traditional experiment.
			  </p>
        {/* What is Pettitt's Test */}
              <h2 className="text-3xl font-bold mb-6 mt-6">What is Pettitt's Test?</h2>
              <p className="mb-6">
                Pettitt's test is a statistical method that can detect significant
                changes in a time series of data. It works by comparing the mean or
                median of the data before and after each point in time, and identifying
                the point where the difference is most significant. This is the
                “change point” where something likely caused the data to shift.
              </p>
              <p className="mb-6">
                When applied to metrics like conversion rate, click-through rate, or
                engagement over time on a web page, Pettitt's test can identify if and
                when a change to the page had a significant impact. While it doesn't
                definitively prove causality, it provides strong evidence that the
                change made a difference.
              </p>

        {/* Advantages of Pettitt's Test */}
              <h2 className="text-3xl font-bold mb-6 mt-6">Advantages of Pettitt's Test</h2>
              <p className="mb-6">
                One advantage of Pettitt's test over A/B testing is that you can use it
                on a single version of a page. You don't need to split traffic between
                a control and variant. This makes it useful for lower-traffic pages or
                when you can only change the live version due to technical or other
                constraints. It can also serve as a safety net, allowing you to check
                if a change you made had unintended consequences even if you didn't or
                couldn't run an A/B test.
              </p>
              <p className="mb-6">
                Another powerful aspect of Pettitt's test is that you can use it to
                detect multiple change points by splitting the data. After identifying
                an initial change point, you can separate the data at that point and run
                the test again on each subset. This can reveal if there were any
                additional significant changes after the first one.
              </p>
              <p className="mb-6">
                For example, imagine you made a major update to your homepage and want
                to know its impact. You could use Pettitt's test to find the most
                significant change point in your conversion rate time series after the
                update. If you find one, you can be fairly confident the update had an
                effect. You could then split the data at that change point and run the
                test separately on the data before and after it. If you find additional
                change points, this could indicate that the update had ripple effects
                over time, or that other factors came into play later on.
              </p>


        

            <div className="relative pt-4 pb-8 px-4 text-white bg-darkBlue h-[400px] w-full">
              <p className="text-base mb-4 mt-8 text-center sticky z-10 top-0 bg-darkBlue">
                Example engagement trend graph with step change
              </p>
              <div className="overflow-x-auto">
                <div className="min-w-[1200px]">
                  <ExampleBarChartComponent
                    engagementData={dummyEngagementDataStepChange}
                    completedTasks={[]}
                  />
                </div>
              </div>
            </div>

   


              <h2 className="text-3xl font-bold mb-6 mt-6">Limitations of Pettitt's Test</h2>
              <p className="mb-6">
                Pettitt's test is not a complete replacement for A/B testing.
                It doesn't give you the same level of control, and you can't use it to
                compare two different versions of a page or feature. But when A/B
                testing isn't feasible, Pettitt's test is a valuable tool to have in your
                data analysis toolkit. Combined with other methods like user research and
                heuristic analysis, it can help you keep improving your site or app even
                when you're constrained in the kinds of tests you can run.
              </p>


              <h2 className="text-3xl font-bold mb-6 mt-6">
                Using Pettitt's Test with contentEnhance
              </h2>
              <p className="mb-6">
                With <strong>contentEnhance</strong>, you can easily apply Pettitt's test to
                key engagement metrics for your pages, such as conversion rate, time on
                page, and bounce rate.
              </p>
              <p className="mb-6">
                Once you've connected your analytics data to contentEnhance and chosen
                the pages you want to analyze, the tool will automatically run Pettitt's
                test to identify potential change points. This allows you to quickly see
                if and when changes you've made to your content have had a significant
                impact, without needing to set up a separate A/B test.
              </p>
              <p className="mb-6">
                contentEnhance also makes it easy to dive deeper by selecting new date
                ranges, which will rerun the analysis. This can uncover additional change
                points that may have been masked in the overall data, giving you a more
                granular view of how your page has evolved over time.
              </p>
              <p className="mb-6">
                By combining Pettitt's test with contentEnhance's other features like
                AI-powered content optimization ideas and the ability to track the impact
                of your changes, you have a comprehensive toolkit for continuously
                improving your key pages and achieving your engagement goals. Even if
                you're already running A/B tests, Pettitt's test in contentEnhance can
                provide an additional layer of insight to help you understand and act on
                your performance data.
              </p>
					</div>
					</div>
					</section>


        {/* Pricing Section */}
        <section id="pricing" className="pricing-section">
          <Pricing />
        </section>

      </div>

      {/* Footer */}
      <Footer />
    </div>
  );
};

export default ChangePointDetection;
