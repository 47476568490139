import React, { useState, useEffect } from 'react';
import { Bar } from 'react-chartjs-2';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  LineElement,
  PointElement,
  Title,
  Tooltip,
  Legend,
  LineController,
} from 'chart.js';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  LineElement,
  PointElement,
  Title,
  Tooltip,
  Legend,
  LineController
);

function percentile(arr, p) {
  if (arr.length === 0) return 0;
  if (p <= 0) return arr[0];
  if (p >= 1) return arr[arr.length - 1];
  const sortedArr = [...arr].sort((a, b) => a - b);
  const index = (sortedArr.length - 1) * p;
  const lower = Math.floor(index);
  const upper = lower + 1;
  const weight = index % 1;
  return upper >= sortedArr.length
    ? sortedArr[lower]
    : sortedArr[lower] * (1 - weight) + sortedArr[upper] * weight;
}

const smoothData = (data) => {
  const smoothedData = [];
  const smoothingFactor = 0.1;
  data.reduce((previous, current) => {
    const smoothedValue = Math.round(previous + (current - previous) * smoothingFactor);
    smoothedData.push(smoothedValue);
    return smoothedValue;
  }, data[0]);
  return smoothedData;
};

function pettittsTest(data) {
  const n = data.length;
  let K = new Array(n).fill(0);

  for (let t = 0; t < n; t++) {
    let s = 0;
    for (let i = 0; i <= t; i++) {
      for (let j = t + 1; j < n; j++) {
        if (data[j] > data[i]) {
          s += 1;
        } else if (data[j] < data[i]) {
          s -= 1;
        }
      }
    }
    K[t] = s;
  }

  const Kmax = Math.max(...K.map(Math.abs));
  const KmaxIndex = K.findIndex((k) => Math.abs(k) === Kmax);

  const nFloat = parseFloat(n);
  const pValue = 2 * Math.exp((-6 * Kmax ** 2) / (nFloat ** 3 + nFloat ** 2));

  return { changePoint: KmaxIndex, pValue };
}

// Recursive function to find multiple change points
function findMultipleChangePoints(data, significanceLevel = 0.05) {
  const changePoints = [];

  const recursivePettittTest = (data) => {
    const { changePoint, pValue } = pettittsTest(data);

    if (pValue < significanceLevel && changePoint !== -1) {
      // Record the change point
      changePoints.push({ changePoint, pValue });

      // Re-run on the data before the change point
      recursivePettittTest(data.slice(0, changePoint));
    }
  };

  recursivePettittTest(data);

  return changePoints;
}

const ExampleBarChartComponent = ({
  engagementData,
  organicData,
  internalData,
  completedTasks,
}) => {
  // Compute the prerender flag at the very beginning.
  const isHeadless =
    typeof navigator !== 'undefined' &&
    navigator.userAgent.includes('ReactSnap');

  // Always call hooks unconditionally.
  const [metricType, setMetricType] = useState('total');
  const [metricView, setMetricView] = useState('engagement_time');
  const [showSmoothedData, setShowSmoothedData] = useState(false);
  const ninetyDaysAgo = new Date();
  ninetyDaysAgo.setDate(ninetyDaysAgo.getDate() - 90);
  const [startDate, setStartDate] = useState(new Date('2024-10-01'));
  const [endDate, setEndDate] = useState(new Date('2024-10-30'));
  const [chartData, setChartData] = useState({ labels: [], datasets: [], yMax: 0 });
  const [isChangePointSignificant, setIsChangePointSignificant] = useState(false);

  const defaultColor = metricView === 'engagement_time' ? '#CDA2D3' : '#FFFFE0';

  useEffect(() => {
    const formatChartData = () => {
      let data, sessions, engagementRate;
      const filteredData = engagementData.filter(
        (item) => new Date(item.date) >= startDate && new Date(item.date) <= endDate
      );

      switch (metricType) {
        case 'total':
          data = filteredData.map((item) => Math.round(item.engagement_time));
          sessions = filteredData.map((item) => item.sessions);
          engagementRate = filteredData.map((item) =>
            parseFloat((item.engagement_rate * 100).toFixed(2))
          );
          break;
        case 'internal':
          data = internalData
            .filter((item) => new Date(item.date) >= startDate && new Date(item.date) <= endDate)
            .map((item) => Math.round(item.engagement_time));
          sessions = internalData
            .filter((item) => new Date(item.date) >= startDate && new Date(item.date) <= endDate)
            .map((item) => item.sessions);
          engagementRate = internalData
            .filter((item) => new Date(item.date) >= startDate && new Date(item.date) <= endDate)
            .map((item) => parseFloat((item.engagement_rate * 100).toFixed(2)));
          break;
        case 'organic':
          data = organicData
            .filter((item) => new Date(item.date) >= startDate && new Date(item.date) <= endDate)
            .map((item) => Math.round(item.engagement_time));
          sessions = organicData
            .filter((item) => new Date(item.date) >= startDate && new Date(item.date) <= endDate)
            .map((item) => item.sessions);
          engagementRate = organicData
            .filter((item) => new Date(item.date) >= startDate && new Date(item.date) <= endDate)
            .map((item) => parseFloat((item.engagement_rate * 100).toFixed(2)));
          break;
        default:
          data = [];
          sessions = [];
          engagementRate = [];
      }

      const metricData = metricView === 'engagement_time' ? data : engagementRate;
      const labels = filteredData.map((item) => item.date);

      let chartMetricData = metricData;
      if (showSmoothedData) {
        chartMetricData = smoothData(metricData);
      }

      const yMaxRaw = Math.max(...metricData);
      const yMaxSmoothed = Math.max(...chartMetricData);
      const yMax = showSmoothedData ? yMaxSmoothed : yMaxRaw;

      // Find multiple change points
      const changePoints = findMultipleChangePoints(metricData);
      setIsChangePointSignificant(changePoints.length > 0);

      // Prepare background colors
      const backgroundColors = chartMetricData.map((_, index) => {
        const isChangePoint = changePoints.some((cp) => cp.changePoint === index);
        return isChangePoint ? '#ff6961' : defaultColor;
      });

      return {
        labels,
        datasets: [
          {
            type: 'bar',
            label: metricView === 'engagement_time' ? 'Engagement Time' : 'Engagement Rate',
            backgroundColor: backgroundColors,
            data: chartMetricData,
          },
          {
            type: 'line',
            label: 'Sessions',
            borderColor: 'lightblue',
            backgroundColor: 'rgba(173, 216, 230, 0.5)',
            data: sessions,
            fill: false,
            yAxisID: 'y1',
          },
        ],
        yMax,
      };
    };

    setChartData(formatChartData());
  }, [
    metricType,
    metricView,
    engagementData,
    internalData,
    organicData,
    startDate,
    endDate,
    completedTasks,
    showSmoothedData,
    defaultColor,
  ]);

  const options = {
    scales: {
      x: {
        grid: {
          display: false,
        },
        ticks: {
          color: 'white',
        },
      },
      y: {
        grid: {
          display: false,
        },
        ticks: {
          color: metricView === 'engagement_time' ? '#CDA2D3' : '#FFFFE0',
        },
        title: {
          display: true,
          text: metricView === 'engagement_time' ? 'Engagement Time' : 'Engagement Rate',
          color: metricView === 'engagement_time' ? '#CDA2D3' : '#FFFFE0',
        },
        max: chartData.yMax,
        min: 0,
        beginAtZero: true,
      },
      y1: {
        type: 'linear',
        display: true,
        position: 'right',
        min: 0,
        beginAtZero: true,
        grid: {
          drawOnChartArea: false,
        },
        ticks: {
          color: 'lightblue',
        },
        title: {
          display: true,
          text: 'Sessions',
          color: 'lightblue',
        },
      },
    },
    plugins: {
      legend: {
        labels: {
          color: 'white',
          generateLabels: (chart) => {
            const labels = ChartJS.defaults.plugins.legend.labels.generateLabels(chart);
            const datasets = chart.data.datasets;

            // Add custom legend item for significant change points
            if (isChangePointSignificant) {
              labels.push({
                text: 'Significant Change Point',
                fillStyle: '#ff6961',
                strokeStyle: '#ff6961',
                lineWidth: 1,
                hidden: false,
                index: datasets.length,
                color: 'white',
              });
            }

            return labels;
          },
        },
        display: true,
        position: 'top',
      },
      tooltip: {
        enabled: true,
        callbacks: {
          afterBody: (tooltipItems) => {
            const date = chartData.labels[tooltipItems[0].dataIndex];
            const tasksForDate = completedTasks.filter(
              (task) => new Date(task.completed_date).toISOString().substring(0, 10) === date
            );

            if (tasksForDate.length > 0) {
              return tasksForDate.map(
                (task) =>
                  `Task set live: ${
                    task.recommendations.length > 150
                      ? `${task.recommendations.substring(0, 150)}...`
                      : task.recommendations
                  }`
              );
            }

            return ['No tasks completed on this date'];
          },
        },
      },
    },
    interaction: {
      mode: 'nearest',
      axis: 'x',
      intersect: false,
    },
    responsive: true,
    maintainAspectRatio: false,
  };

  // Conditionally render nothing if prerendering
  if (isHeadless) {
    return null;
  }

  return (
    <div className="flex flex-col items-center w-full">
      {/* Top controls */}
      <div className="flex flex-wrap items-center justify-center mb-4 gap-4">
        <select
          className="text-sm px-3 py-1 rounded-md shadow-sm text-white bg-blue-500"
          value={metricType}
          onChange={(e) => setMetricType(e.target.value)}
        >
          <option value="total">All Traffic</option>
          <option value="internal">Internal Traffic</option>
          <option value="organic">Organic Search Traffic</option>
        </select>

        <select
          className="text-sm px-3 py-1 rounded-md shadow-sm text-white bg-pink-500"
          value={metricView}
          onChange={(e) => setMetricView(e.target.value)}
        >
          <option value="engagement_time">Engagement Time</option>
          <option value="engagement_rate">Engagement Rate</option>
        </select>

        <div className="flex items-center">
          <input
            type="checkbox"
            id="showSmoothedData"
            checked={showSmoothedData}
            onChange={(e) => setShowSmoothedData(e.target.checked)}
            className="mr-2"
          />
          <label htmlFor="showSmoothedData" className="text-white text-sm">
            Show smoothed data
          </label>
        </div>

        <DatePicker
          selected={startDate}
          onChange={(date) => setStartDate(date)}
          className="text-sm px-3 py-1 border border-gray-600 rounded-md shadow-sm bg-gray-900 text-gray-200"
        />
        <DatePicker
          selected={endDate}
          onChange={(date) => setEndDate(date)}
          className="text-sm px-3 py-1 border border-gray-600 rounded-md shadow-sm bg-gray-900 text-gray-200"
        />

        <button className="bg-gray-700 text-white px-3 py-1 text-sm rounded-md hover:bg-gray-900">
          Export CSV
        </button>
      </div>

      {/* Chart container */}
      <div className="w-full h-[230px]">
        <Bar data={chartData} options={options} />
      </div>
    </div>
  );
};

export default ExampleBarChartComponent;
