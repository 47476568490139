import React, { useEffect } from 'react';
import { useCookies } from 'react-cookie';
import { useLocation } from 'react-router-dom';
import { pageview } from './gtm-utils'; // If you have a custom function

const GoogleTM = () => {
  const [cookies] = useCookies(['consent']);
  const location = useLocation();

  useEffect(() => {
    // Example manual pageview (for single-page apps)
    if (location.pathname) {
      pageview(location.pathname);
    }

    // --- 1. Insert the FULL GTM snippet if it’s not already loaded ---
    if (!window.gtmLoaded) {
      window.gtmLoaded = true; // Simple global flag to avoid re-injecting

      // Inline script to push 'gtm.js' event and load the external script
      const inlineScript = document.createElement('script');
      inlineScript.id = 'gtm-inline-snippet';
      inlineScript.innerHTML = `
        (function(w,d,s,l,i){
          w[l] = w[l] || [];
          w[l].push({
            'gtm.start': new Date().getTime(),
            event: 'gtm.js'
          });
          var f = d.getElementsByTagName(s)[0],
              j = d.createElement(s),
              dl = l != 'dataLayer' ? '&l=' + l : '';
          j.async = true;
          j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
          f.parentNode.insertBefore(j, f);
        })(window, document, 'script', 'dataLayer', 'GTM-5TJTMRX2');
      `;
      document.head.appendChild(inlineScript);
    }

    // --- 2. Define a gtag helper, if needed ---
    window.dataLayer = window.dataLayer || [];
    window.gtag = function gtag(){ window.dataLayer.push(arguments); };

    // --- 3. Apply consent settings based on the user's cookie ---
    const consentGiven = cookies.consent;

    if (consentGiven === 'true') {
      window.gtag('consent', 'update', {
        ad_storage: 'granted',
        analytics_storage: 'granted',
        functionality_storage: 'granted',
        personalization_storage: 'granted',
        security_storage: 'granted',
      });
    } else {
      window.gtag('consent', 'default', {
        ad_storage: 'denied',
        analytics_storage: 'denied',
        functionality_storage: 'denied',
        personalization_storage: 'denied',
        security_storage: 'denied',
      });
    }

    // --- 4. Listen for the custom event “updateGTMConsent” (from CookieConsent Accept) ---
    const handleConsentUpdate = () => {
      window.gtag('consent', 'update', {
        ad_storage: 'granted',
        analytics_storage: 'granted',
        functionality_storage: 'granted',
        personalization_storage: 'granted',
        security_storage: 'granted',
      });

      // Optionally push a custom event so GTM can trigger tags
      window.dataLayer.push({ event: 'cookie_consent_given' });
    };

    window.addEventListener('updateGTMConsent', handleConsentUpdate);

    // Cleanup
    return () => {
      window.removeEventListener('updateGTMConsent', handleConsentUpdate);
    };
  }, [location, cookies]);

  return (
    <>
      {/* The noscript fallback for GTM */}
      <noscript>
        <iframe
          src="https://www.googletagmanager.com/ns.html?id=GTM-5TJTMRX2"
          height="0"
          width="0"
          style={{ display: 'none', visibility: 'hidden' }}
          title="Google Tag Manager"
        />
      </noscript>
    </>
  );
};

export default GoogleTM;
