import React from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import TopBar from './TopBar';
import Sidebar from './Sidebar';
import Footer from './Footer';
import './ContentPerformance.css'; // Reuse the same CSS as in ContentPerformance

const ForbiddenPage = () => {
	  return (
	    <div className="flex flex-col h-screen bg-darkBlue">
	      <Helmet>
	        <title>contentEnhance</title>
	        <meta name="robots" content="noindex, nofollow" />
	      </Helmet>
	      <TopBar />
	      <div className="flex h-screen bg-lighterDarkBlue text-white">
	        <Sidebar />
	        <div className="flex-1 p-10 overflow-auto">

	        <p className="text-lg mb-6 mt-16 text-sm">Forbidden</p>
	        <p className="text-lg mb-6 text-sm">You don't have access to this project.</p>
	        <Link to="/workspaces" className="text-sm text-blue-500 text-lg hover:underline">
	          Go back to the workspaces
	        </Link>
	      </div>
	    </div>
		      </div>
	  );
	};

export default ForbiddenPage;