import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import TopBarMarketing from './TopBarMarketing';
import './ContentPerformance.css'; // Reuse the same CSS as in ContentPerformance
import Footer from './Footer';
import { Helmet } from 'react-helmet';
import Pricing from './Pricing';
// (Optional) If you want to show a Sankey or "journey" style diagram, import it:
import SankeyDiagramExampleComponent from './SankeyDiagramExampleComponent';
import productOverview from './images/product-overview.gif';
import waybackMachine from './images/wayback-machine-contentenhance.gif'
import ga4Path from './images/google-analytics-path-exploration.png'

const JourneyFlowsGA4 = () => {
	
	const StyledCodeBlock = () => {
	  const [isExpanded, setIsExpanded] = useState(false);

	  const toggleCode = () => {
	    setIsExpanded((prev) => !prev);
	  };
    return (
      <pre className="bg-gray-900 p-6 rounded-lg overflow-x-auto mb-6 text-gray-100">
        <code className="language-python">
          {/* Preview content always visible */}
          <div className="preview-content">
            { `from google.analytics.data_v1beta import BetaAnalyticsDataClient
		   from google.analytics.data_v1beta.types import (
		       RunReportRequest,
		       Dimension,
		       Metric,
		       DateRange,
		       Filter,
		       FilterExpression,
		       FilterExpressionList,
		       OrderBy
		   )

		   def analyze_ga4_journeys(property_id, landing_page, start_date, end_date):
		       """
		       Analyze user journey data in GA4 by combining pageReferrer and linkUrl
		       dimensions to understand both where users came from and where they're going.
    
		       Args:
		           property_id: GA4 property ID
		           landing_page: Page path to analyze (e.g., '/blog/post-1')
		           start_date: Start date in YYYY-MM-DD format
		           end_date: End date in YYYY-MM-DD format
    
		       Returns:
		           dict: Combined journey analysis results
		       """
		       client = BetaAnalyticsDataClient()`}
          </div>

          {/* Toggle button */}
          <button
            className="bg-gray-700 text-gray-200 px-3 py-1 rounded-md mt-2 hover:bg-gray-600 text-sm"
            onClick={toggleCode}
          >
            {isExpanded ? 'Show less' : 'Show more'}
          </button>

          {/* Hidden content shown when expanded */}
          {isExpanded && (
            <div className="hidden-content">
              { `def get_top_paths(dimension_name, filters):
		           """Helper to fetch and process path data from GA4"""
		           request = RunReportRequest(
		               property=f"properties/{property_id}",
		               dimensions=[Dimension(name=dimension_name)],
		               metrics=[Metric(name="sessions")],
		               date_ranges=[DateRange(
		                   start_date=start_date,
		                   end_date=end_date
		               )],
		               dimension_filter=FilterExpression(
		                   and_group=FilterExpressionList(
		                       expressions=filters
		                   )
		               ),
		               order_bys=[OrderBy(
		                   metric=OrderBy.MetricOrderBy(metric_name="sessions"),
		                   desc=True
		               )]
		           )
        
		           response = client.run_report(request)
        
		           # Process response into a dictionary
		           results = {}
		           for row in response.rows:
		               path = row.dimension_values[0].value
		               sessions = int(row.metric_values[0].value)
		               results[path] = sessions
            
		           return results

		       # Get referring pages using pageReferrer dimension
		       referrer_filters = [
		           FilterExpression(
		               filter=Filter(
		                   field_name="pagePath",
		                   string_filter=Filter.StringFilter(
		                       value=landing_page,
		                       match_type=Filter.StringFilter.MatchType.EXACT
		                   )
		               )
		           )
		       ]
    
		       referrer_data = get_top_paths("pageReferrer", referrer_filters)
    
		       # Get outbound clicks using linkUrl dimension
		       link_filters = [
		           FilterExpression(
		               filter=Filter(
		                   field_name="pagePath",
		                   string_filter=Filter.StringFilter(
		                       value=landing_page,
		                       match_type=Filter.StringFilter.MatchType.EXACT
		                   )
		               )
		           )
		       ]
    
		       link_data = get_top_paths("linkUrl", link_filters)
    
		       # Process results to include only top 5 with "Other" category
		       def get_top_results(data, limit=5):
		           sorted_data = sorted(data.items(), key=lambda x: x[1], reverse=True)
		           top_results = dict(sorted_data[:limit])
        
		           other_sum = sum(dict(sorted_data[limit:]).values())
		           if other_sum > 0:
		               top_results['Other'] = other_sum
            
		           return top_results
    
		       journey_analysis = {
		           'incoming_traffic': get_top_results(referrer_data),
		           'outgoing_traffic': get_top_results(link_data),
		           'summary': {
		               'total_incoming_sessions': sum(referrer_data.values()),
		               'total_outgoing_clicks': sum(link_data.values()),
		               'most_common_entry_point': max(referrer_data.items(), key=lambda x: x[1])[0] if referrer_data else None,
		               'most_common_exit_point': max(link_data.items(), key=lambda x: x[1])[0] if link_data else None
		           }
		       }
    
		       return journey_analysis

		   # Example usage:
		   if __name__ == "__main__":
		       results = analyze_ga4_journeys(
		           property_id="123456789",
		           landing_page="/blog/top-posts",
		           start_date="2024-01-01",
		           end_date="2024-01-31"
		       )
    
		       print("Top incoming traffic sources:")
		       for source, sessions in results['incoming_traffic'].items():
		           print(f"  {source}: {sessions} sessions")
        
		       print("\nTop outgoing destinations:")
		       for dest, clicks in results['outgoing_traffic'].items():
		           print(f"  {dest}: {clicks} clicks")
        
		       print("\nSummary:")
		       for metric, value in results['summary'].items():
		           print(f"  {metric}: {value}")`}
            </div>
          )}
        </code>
      </pre>
    );
  };
	
	
	const dummyTotalSessions = 500;
    const dummyNextUrls = [
        { next_url: '/about', sessions: 130, percentage: 20 },
        { next_url: '/contact', sessions: 150, percentage: 30 },
        { next_url: '/products', sessions: 90, percentage: 25 },
        { next_url: '/blog', sessions: 40, percentage: 15 },
        { next_url: '/faq', sessions: 30, percentage: 5 },
        { next_url: '/support', sessions:20, percentage: 5 },
    ];
  

    const dummyTopReferringUrls = [
        { referring_url: 'google.com', sessions: 150 },
        { referring_url: 'facebook.com', sessions: 100 },
        { referring_url: 'linkedin.com', sessions: 80 },
        { referring_url: 'twitter.com', sessions: 60 },
        { referring_url: 'bing.com', sessions: 40 },
        { referring_url: 'yahoo.com', sessions: 30 },
        { referring_url: 'duckduckgo.com', sessions: 20 },
        { referring_url: 'instagram.com', sessions: 10 },
    ];
  

    const dummyTopSourceMediums = [
        { source_medium: '(direct) / (none)', sessions: 300 },
        { source_medium: 'email / newsletter', sessions: 200 },
        { source_medium: 'google / organic', sessions: 150 },
        { source_medium: 'facebook / referral', sessions: 100 },
        { source_medium: 'instagram / referral', sessions: 50 },
        { source_medium: 'linkedin / referral', sessions: 30 },
        { source_medium: 'bing / cpc', sessions: 20 },
        { source_medium: 'twitter / organic', sessions: 15 },
    ];
  return (
    <div className="flex flex-col min-h-screen text-black">
      <Helmet>
        <title>Visualize Website Journey Flows with GA4</title>
        <meta
          name="description"
          content="Learn how to visualize website journey flows using GA4 and optimize user experience."
        />
      </Helmet>

      <TopBarMarketing />

      <div className="container mt-12">
        {/* Section 1: Introduction / Hero */}
        <section id="journey-introduction" className="breakout-block">
          <div className="why-section">
            <div className="why-block">
              <div className="why-text">
                <p className="text-sm text-gray-500 mb-6 mt-12">
                  UNDERSTANDING USER JOURNEY FLOWS
                </p>
                <h1 className="text-6xl font-bold mb-6">
                  Visualize Website Journey Flows with GA4
                </h1>
                <p className="mb-6">
                  Visualizing how users move through your website is crucial for understanding user behavior and optimizing the user experience. Google Analytics 4 (GA4) provides several tools and metrics to help you analyze these journey flows, though the approach differs from Universal Analytics. However, for teams looking for a more user-friendly alternative, contentEnhance offers specialized journey mapping capabilities designed specifically for content teams.
                </p>
                <Link to="/signup" className="button-link">
                  Sign up free
                </Link>
              </div>
    <div className="bg-darkBlue w-full sm:w-[700px] mt-6">
      <SankeyDiagramExampleComponent
        url="https://example.com"
        nextUrls={dummyNextUrls}
        topReferringUrls={dummyTopReferringUrls}
        topSourceMediums={dummyTopSourceMediums}
        totalSessions={dummyTotalSessions}
      />
    </div>
            </div>
          </div>
        </section>

		{/* Section 2: Detailed Explanation */}
<section id="journey-details" className="breakout-block">
  <div className="performance-section">
    <div className="performance-text">
      <h2 className="text-3xl font-bold mb-6 mt-6">
        Available Journey Visualization Methods in GA4
      </h2>
      <p className="mb-6">
        <strong>Path Exploration Report:</strong> The primary tool for
        visualizing user journeys. Located under <em>Explore &gt; Path Exploration</em>,
        this report allows you to:
      </p>
      <ul className="mb-6 list-disc ml-6">
        <li>View the sequence of pages/screens users visit</li>
        <li>Analyze where users start their journey</li>
        <li>Identify where users drop off</li>
        <li>Segment users based on various dimensions</li>
      </ul>
		<img src={ga4Path} alt="Image showing GA4 path exploration" loading="lazy" />

      <h2 className="text-3xl font-bold mb-6 mt-6">
        Key Variables for Journey Analysis
      </h2>
      <p className="mb-6">
       GA4 (Google Analytics 4) provides several powerful variables to help analyze user flows and behavior across your digital properties. These dimensions and metrics enable detailed tracking of how users interact with your content:
      </p>
      <ul className="mb-6 list-disc ml-6">
        <li>Page path and page title: Track the specific URLs and page names users visit, allowing you to understand the most popular content and navigation patterns through your site.</li>
        <li>Event names: Custom and automatic events that capture specific user interactions like button clicks, form submissions, video plays, scroll depth, and file downloads.</li>
        <li>Session source/medium: Identify where your traffic is coming from, whether it's organic search, paid campaigns, social media, email marketing, or direct visits.</li>
        <li>User demographics: Understand your audience through characteristics like age ranges, gender, interests, and language preferences, helping you tailor content to your visitors.</li>
        <li>Device category: Monitor how users access your site across desktop, mobile, and tablet devices, enabling you to optimize the experience for each platform.</li>
        <li>Geographic location: View user distribution across countries, regions, and cities to understand your global audience reach and adapt content for different markets.</li>
      </ul>
	   <p className="mb-6">These variables can be combined in custom reports and explorations to gain deeper insights into user behavior and optimize your digital strategy.</p>

      <h2 className="text-3xl font-bold mb-6 mt-6">
        Setting Up Journey Analysis in GA4
      </h2>
      <p className="mb-6">
        You can set up journey analysis via the UI or through the API.
      </p>
<h3 className="text-2xl font-bold mb-4">Via the UI</h3>
      <ul className="mb-6 list-disc ml-6">
        <li>Navigate to the Explore section in your GA4 property dashboard, located in the left navigation menu</li>
        <li>Select Path Exploration from the template gallery to visualize user journey flows</li>
        <li>Choose your starting point by selecting a key entry page or event (e.g., homepage, landing page, or conversion event)</li>
        <li>Select the specific nodes and metrics you want to analyze, such as page views, events, or custom parameters</li>
        <li>Apply segments or filters to focus on specific user groups, time periods, or behaviors for deeper analysis</li>
      </ul>
	     <h3 className="text-2xl font-bold mt-6">Via the API</h3>
  <p className="mb-6">
    You'll need to use both the <code>pageReferrer</code> and <code>linkUrl</code> dimensions to get comprehensive journey data in GA4. These complementary dimensions work together to provide a complete picture of user navigation patterns and behavior flows.
  </p>
<p className="font-bold mb-6 mt-6">Key GA4 Journey Dimensions</p>
      <p className="font-bold mb-6 mt-6">pageReferrer</p>
      <p className="mb-6">Tracks the full URL of the previous page that led to the current page view, enabling precise path analysis</p>
      <ul className="mb-6 list-disc ml-6">
        <li>Essential for mapping internal navigation patterns and understanding how users move between pages within your site</li>
        <li>Captures server-side page transitions and provides reliable data even when client-side tracking is limited</li>
      </ul>
      <p className="font-bold mb-6 mt-6">linkUrl</p>
      <p className="mb-6">Captures the destination URLs of links clicked by users, providing predictive journey insights</p>
      <ul className="mb-6 list-disc ml-6">
        <li>Reveals user intentions by showing which links they interact with before leaving the current page</li>
        <li>Critical for tracking both internal navigation patterns and external link interactions across your digital properties</li>
      </ul>
      <p className="font-bold mb-6 mt-6">Data Collection Process</p>
      <p className="mb-6">To get complete journey data, you need to:</p>
      <ul className="mb-6 list-disc ml-6">
        <li>Query both dimensions separately since they capture different aspects of the journey:</li>
        <ul className="list-disc ml-8">
          <li><code>pageReferrer</code> provides historical context by revealing the user's previous location</li>
          <li><code>linkUrl</code> indicates user intent by showing their next destination</li>
        </ul>
        <li>Combine and normalize the data:</li>
        <ul className="list-disc ml-8">
          <li>Standardize relative paths into complete, canonical URLs for consistent analysis</li>
          <li>Calculate meaningful session metrics to understand volume and frequency</li>
          <li>Apply special handling for homepage visits and direct traffic patterns</li>
        </ul>
      </ul>
      <p className="font-bold mb-6 mt-6">Implementation Considerations</p>
      <ul className="mb-6 list-disc ml-6">
        <li>Implement robust regex patterns to handle URL variations and ensure accurate path matching</li>
        <li>Standardize URL formats by accounting for trailing slashes and query parameter variations</li>
        <li>Configure proper hostname matching rules for websites spanning multiple domains or subdomains</li>
        <li>Weight journey analysis using session counts to identify significant patterns</li>
        <li>Build comprehensive error handling to manage API timeouts and data anomalies</li>
        <li>Select appropriate date ranges to balance data volume with meaningful insights</li>
      </ul>
      <p className="font-bold mb-6 mt-6">Data Processing Steps</p>
      <ul className="mb-6 list-disc ml-6">
        <li>Execute parallel API requests to efficiently gather both <code>pageReferrer</code> and <code>linkUrl</code> data sets</li>
        <li>Transform URLs into a standardized format following your organization's conventions</li>
        <li>Merge the dimension data sets to create comprehensive journey mappings</li>
        <li>Order results by session volume to identify primary user paths</li>
        <li>Consolidate low-traffic destinations into an "Other" category for cleaner analysis</li>
        <li>Implement exception handling for missing data and invalid URL formats</li>
      </ul>
	   <p className="mb-6">Here is some example Python code to get you going:</p>
	   
	    <StyledCodeBlock />
	   
      <p className="mb-6 mt-6">This comprehensive approach ensures you capture the complete user journey by combining both historical navigation data and forward-looking user intentions, enabling sophisticated path analysis and optimization opportunities.</p>
      <h2 className="text-3xl font-bold mb-6 mt-6">
        Limitations of GA4 Journey Visualization
      </h2>
      <p className="mb-6">
        While GA4 provides powerful analytics capabilities, it comes with several notable limitations when it comes to journey visualization and analysis:
      </p>
      <h3 className="text-2xl font-bold mb-4">Complex Setup Requirements</h3>
      <p className="mb-6">
        Implementing custom events and setting up proper journey tracking in GA4 requires significant technical expertise. Content teams often need developer support to properly configure event tracking and ensure accurate data collection.
      </p>
      <h3 className="text-2xl font-bold mb-4">Limited Data Retention</h3>
      <p className="mb-6">
        GA4's standard data retention policies can restrict your ability to analyze long-term trends. By default, user-level and event-level data is only kept for a limited time period, making it challenging to perform historical analysis of user journeys beyond this window.
      </p>
      <h3 className="text-2xl font-bold mb-4">Steep Learning Curve</h3>
      <p className="mb-6">
        The GA4 interface represents a significant departure from Universal Analytics, requiring users to learn new terminology, navigation patterns, and reporting structures. This can slow down adoption and make it difficult for teams to quickly access the insights they need.
      </p>
      <h3 className="text-2xl font-bold mb-4">Visualization Constraints</h3>
      <p className="mb-6">
        While GA4 offers basic journey visualization tools, the options for customizing and presenting this data are somewhat limited. Users often find themselves needing additional tools to create more intuitive and shareable visualizations of user flows.
      </p>
      <h3 className="text-2xl font-bold mb-4">API Implementation Challenges</h3>
      <p className="mb-6">
        For teams looking to programmatically access journey data, GA4's API implementation can be complex and requires substantial development resources. The learning curve for working with the API is steep, and documentation can be challenging to navigate for non-technical users.
      </p>

      <h2 className="text-3xl font-bold mb-6 mt-6">
        Alternative Solution: contentEnhance
      </h2>
      <p className="mb-6">
        While GA4 offers powerful journey visualization tools, contentEnhance provides a more user-friendly alternative specifically designed for content teams. Key benefits include:
      </p>
		<h3 className="text-2xl font-bold mb-4">Simplified Journey Mapping</h3>
 <p className="mb-6">
		contentEnhance's URL user journey mapper provides comprehensive visualization of how users interact with your content. The tool clearly displays traffic patterns, showing you exactly where visitors come from and where they go next. This includes:
</p>
		<ul className="mb-6 list-disc ml-6">
		<li>Detailed analysis of external traffic sources, helping you identify which channels drive the most engaged visitors
</li>
		<li>Clear visualization of internal page referrals, revealing how users navigate through your site's content
</li>
		<li>In-depth next destination analysis that helps you understand user behavior and optimize conversion paths
		</li>
		</ul>
    <div className="bg-darkBlue w-full sm:w-[700px] mt-6 mb-6">
      <SankeyDiagramExampleComponent
        url="https://example.com"
        nextUrls={dummyNextUrls}
        topReferringUrls={dummyTopReferringUrls}
        topSourceMediums={dummyTopSourceMediums}
        totalSessions={dummyTotalSessions}
      />
    </div>

		<h3 className="text-2xl font-bold mb-4">Enhanced Analytics Integration</h3>
 <p className="mb-6">
		While GA4 can be complex for content teams to navigate, contentEnhance simplifies analytics by:
		</p>
		<ul className="mb-6 list-disc ml-6">

		<li>Seamlessly importing GA4 data without requiring technical expertise
</li>
		<li>Presenting metrics in an intuitive format specifically designed for content teams
</li>
		<li>Highlighting key engagement metrics that matter most for content performance
</li>
		 <li>Providing a unified view of how content performs across different marketing channels </li>
		</ul>
		
		<img src={productOverview} alt="Product overview for contentEnhance" loading="lazy" />

		<h3 className="text-2xl font-bold mb-4 mt-6">Additional Benefits</h3>
 <p className="mb-6">
		contentEnhance goes beyond basic analytics by offering:
		 </p>
		<ul className="mb-6 list-disc ml-6">

		<li>Intelligent AI-driven optimization suggestions that provide actionable recommendations based on your audience brief
</li>
		<li>Comprehensive task impact tracking that helps you measure the effectiveness of your content improvements
</li>
		<li>Detailed historical content tracking through a personal Wayback Machine feature, allowing you to monitor content evolution over time
</li>
		<li>A strategic audience-first approach that ensures your content meets user needs and expectations
        </li>
		</ul>
		 <p className="mb-6">
		These features combine to create a powerful platform that not only helps you understand user journeys but also provides the tools needed to optimize content based on real user behavior and engagement patterns.
        </p>
		<img src={waybackMachine} alt="contentEnhance feature - wayback machine" loading="lazy" />
		<h2 className="text-3xl font-bold mb-6 mt-6">Summary</h2>

 <p className="mb-6">
		While GA4 provides robust journey visualization capabilities, tools like contentEnhance can offer a more streamlined, content-focused approach. Choose the solution that best fits your team's needs and technical capabilities, keeping in mind the importance of regular journey analysis for ongoing optimization.
        </p>
		
    </div>
  </div>
</section>
        <section id="pricing" className="pricing-section">
          <Pricing />
        </section>
		      </div>

		      <Footer />
		    </div>
		  );
		};

		export default JourneyFlowsGA4;
       