import React from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import TopBar from './TopBar';
import Sidebar from './Sidebar';
import Footer from './Footer';
import './ContentPerformance.css'; // Reuse the same CSS as in ContentPerformance

const NotFoundPage = () => {
  return (
    <div className="flex flex-col h-screen bg-darkBlue">
      <Helmet>
        <title>contentEnhance</title>
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>
      <TopBar />
      <div className="flex h-screen bg-lighterDarkBlue text-white">
        <Sidebar />
        <div className="flex-1 p-10 overflow-auto">

        <p className="text-lg mb-6 mt-16 text-sm">404</p>
        <p className="text-lg mb-6 text-sm">Oops! The page you are looking for does not exist.</p>
        <Link to="/" className="text-sm text-blue-500 text-lg hover:underline">
          Go back to the homepage
        </Link>
      </div>
    </div>
	      </div>
  );
};

export default NotFoundPage;
