import React from 'react';
import { Link } from 'react-router-dom';
import TopBarMarketing from './TopBarMarketing';
import './ContentPerformance.css'; // Reuse the same CSS as in ContentPerformance
import Pricing from './Pricing';
import Footer from './Footer';
import { Helmet } from 'react-helmet';
import supportGif from './images/generate-support.gif';
import productOverview from './images/product-overview.gif';
import audienceBrief from './images/audience-brief.gif';
import cookieManager from './images/cookie-manager.png';
import chooseUrls from './images/choose-urls-gif.gif';

const ContentQualityCheck = () => {
	
	
  return (
    <div className="flex flex-col min-h-screen text-black">
      <Helmet>
        <title>Using AI to Quality Check Your Content with contentEnhance</title>
        <meta
          name="description"
          content="Discover how contentEnhance leverages AI to analyze and optimize your website content by combining visual and semantic analysis."
        />
      </Helmet>

      <TopBarMarketing />

      <div className="container mt-12">
        {/* HERO / CTA Section */}
        <section id="hero" className="breakout-block">
          <div className="why-section">
            <div className="why-block">
              <div className="why-text">
                <p className="text-sm text-gray-500 mb-6 mt-12">
                  REVOLUTIONIZE YOUR CONTENT QUALITY
                </p>
                <h1 className="text-6xl font-bold mb-6">
                  Using AI to Quality Check Your Content
                </h1>
                <p className="mb-6">
                  Artificial intelligence is transforming how we create and optimize content. It allows us to rapidly analyze content both visually and semantically, while incorporating audience intelligence, empowering teams to be more data-driven in optimizing content.
                </p>
                <p className="mb-6">
                  contentEnhance leverages computer vision AI to analyze screenshots of webpages, providing feedback on the visual layout, design, and content experience. By connecting analytics data, contentEnhance identifies pages with high traffic but low engagement that are prime candidates for optimization. It then generates audience-informed ideas and provides implementation support to enhance the content at scale.
                </p>
                <Link to="/signup" className="button-link">
                  Sign up free
                </Link>
		 </div>
		<div className="w-full sm:w-[700px] mt-6 h-[550px] sm:h-[400px] flex justify-center items-center px-4">

<img src={productOverview} alt="Overview of contentEnhance" loading="lazy" />
		


              </div>
            </div>
          </div>
        </section>

        {/* Section: Analyzing Webpages Visually with AI Vision */}
        <section id="ai-vision" className="breakout-block">
	            <div className="performance-section">
	              <div className="performance-text">
            <h2 className="text-3xl font-bold mb-6 mt-6">
              Analyzing Webpages Visually with AI Vision
            </h2>
            <p className="mb-6">
              One key capability is using computer vision AI models to analyze screenshots of webpages. These models can 'see' and understand the visual layout, design elements, media and text on a page, similar to how a human would perceive it. This means they can feedback on the content experience and highlight opportunities for improvement.
            </p>
            <p className="mb-6">
              Current popular models that can help with this are:
            </p>
            <ul className="mb-6 list-disc">
              <li>
                <strong>OpenAI's GPT-4o:</strong> <a href="https://openai.com/index/hello-gpt-4o/">Released in May 2024</a>, GPT-4o is a multilingual, multimodal generative pre-trained transformer that processes and generates text, images, and audio. It offers real-time reasoning across these modalities, enabling comprehensive analysis of webpage visuals.
              </li>
              <li>
                <strong>Anthropic's Claude 3.5 Sonnet:</strong> Launched in June 2024, <a href="https://www.anthropic.com/news/claude-3-5-sonnet">Claude 3.5 Sonnet</a> is a vision model that excels in tasks requiring visual reasoning, such as interpreting charts and graphs. It can accurately transcribe text from images, making it valuable for analyzing visual content on webpages.
              </li>
            </ul>
            <p className="mb-6">
              By analyzing the screenshot of a webpage, computer vision AI can provide feedback on aspects like:
            </p>
            <ul className="mb-6 list-disc">
              <li>
                <strong>Visual hierarchy and layout:</strong> How well the page structure guides the user through the content.
              </li>
              <li>
                <strong>Readability:</strong> Font sizes, contrast, and line lengths that impact ease of reading.
              </li>
              <li>
                <strong>Engagement:</strong> Opportunities to make the content more visually appealing and interactive.
              </li>
              <li>
                <strong>Accessibility:</strong> Alt text, heading structure, and other factors for users with disabilities.
              </li>
            </ul>
            <p className="mb-6">
              In addition to visual analysis, extracting and analyzing the text content of a webpage can provide valuable insights to support content optimization, especially in cases where the text in images is difficult for computer vision models to accurately parse.
            </p>


            <h2 className="text-3xl font-bold mb-6 mt-6">
              How contentEnhance Leverages AI for Content Optimization
            </h2>
            <p className="mb-6">
              contentEnhance is a powerful tool that helps you improve the quality and engagement of your website content using artificial intelligence. It works by analyzing key metrics from your Google Analytics and Google Search Console data to identify pages that are attracting a lot of traffic but have low engagement, indicating there may be opportunities to enhance the content to better meet your audience's needs.
            </p>
            <p className="mb-6">
              To get started, you connect your Google Analytics and Search Console accounts to contentEnhance. The tool will then flag high-traffic, low-engagement pages that you may want to prioritize optimizing.
			  
            </p>
			  <img src={chooseUrls} alt="Choose URLs to track in contentEnhance" loading="lazy" />
            <p className="mb-6 mt-6">
              Next, you provide an audience brief to give the AI models context about who your content is for and what their needs, interests and pain points are. You can write this brief yourself or get assistance from the AI to generate one. This audience insight is critical for enabling the AI to generate relevant content improvement ideas aligned with your users' needs.
            </p>
			  <img src={audienceBrief} alt="contentEnhance helping to generate audience brief" loading="lazy" />
		
            <p className="mb-6">
              Once the setup is complete, the contentEnhance AI agent will access your website, take screenshots of the pages you selected, and generate a set of ideas for how to enhance the content. This could include things like adding more visual elements, answering common audience questions, adjusting the page structure for clarity, elaborating on key points, and more.
            </p>
            <p className="mb-6">
              For each optimization idea, you can also ask the AI for additional implementation support, such as drafting an updated intro or FAQs. You can provide writing guidelines to ensure the AI-generated copy aligns with your brand voice and content standards. The ideas feed into a task manager where you can assign them to team members to action and add your own manual tasks.
            </p>
			  <img src={supportGif} alt="Generating support to improve content" loading="lazy" />
            <p className="mb-6 mt-6">
              As you implement content updates, contentEnhance will automatically track the impact on your engagement metrics over time. Screenshots are taken daily so you have a historical record of how your content has evolved.
            </p>
			  
			  

            <h2 className="text-3xl font-bold mb-6 mt-6">
              AI Assistants Might Not Be Able To Access Your Content
            </h2>
            <p className="mb-6">
              Some websites employ techniques that can pose challenges for AI assistants when trying to access and analyze the content:
            </p>
            <ul className="mb-6 list-disc">
              <li>
                <strong>IP-based Redirects:</strong> Certain sites may redirect traffic based on the IP address of the visitor. If the AI plugin is accessing the site from an IP that gets redirected, it may end up analyzing a different version of the page than what users see. This could lead to inaccurate content optimization suggestions.
              </li>
              <li>
                <strong>Client-side Rendering:</strong> Some modern websites, especially single page apps (SPAs), render most of the content on the client-side using JavaScript after the initial page load. If the AI plugin only analyzes the initial HTML payload without executing the scripts, it would miss the actual content users see.
              </li>
              <li>
                <strong>Bot Detection:</strong> Some websites employ techniques to detect and block access from bots, web crawlers, and automated scripts. If the AI plugin exhibits non-human browsing patterns or lacks typical browser characteristics, it may get flagged and blocked from accessing the content. This would prevent it from analyzing pages and providing optimization suggestions.
              </li>
            </ul>

            <h2 className="text-3xl font-bold mb-6 mt-6">
              How contentEnhance Solves Content Accessibility Issues
            </h2>
            <p className="mb-6">
              contentEnhance uses advanced techniques to ensure the AI can reliably access and analyze your website content, even on modern web apps with complex client-side rendering.
            </p>
            <p className="mb-6">
              Our system employs a headless browser that fully loads and renders pages, executing all the JavaScript, before the AI processes the content. This means the AI analyzes the actual page that users see, not just the initial HTML.
            </p>
            <p className="mb-6">
              To get around IP-based bot detection, contentEnhance provides a dedicated static IP address that you can whitelist in your server configuration. This tells your website to treat our AI as a first-class visitor and fully render pages for it.
            </p>
            <p className="mb-6">
              Finally, for pages that require login sessions to access, you can configure cookies and local storage values in contentEnhance. Our headless browser will use these to authenticate and retrieve gated content for the AI to analyze.
            </p>
			   <img src={cookieManager} alt="Cookie Manager in contentEnhance to get behnd login pages" loading="lazy" />
            <p className="mb-6 mt-6">
              With these capabilities, contentEnhance delivers AI-powered content optimization while seamlessly handling the latest web technologies and access control methods. It ensures the AI provides relevant, accurate suggestions based on the real user experience of your site.
            </p>



            <h2 className="text-3xl font-bold mb-6 mt-6">
              contentEnhance Empowers Content Teams
            </h2>
            <p className="mb-6">
              By harnessing the power of AI to rapidly analyze content both visually and semantically, while incorporating audience intelligence and writing best practices, contentEnhance enables teams to efficiently quality check and optimize their content. This ultimately improves the user experience and drives better business results.
            </p>
					</div>
					</div>
					</section>

        {/* Pricing Section */}
        <section id="pricing" className="pricing-section">
          <Pricing />
        </section>
      </div>

      {/* Footer */}
      <Footer />
    </div>
  );
};

export default ContentQualityCheck;
