import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import 'chart.js/auto';

const ReadingTimeChartComponent = ({ data, percentage }) => {
  // Compute the prerender flag at the very beginning.
  const isHeadless =
    typeof navigator !== 'undefined' &&
    navigator.userAgent.includes('ReactSnap');

  // If running in the prerender environment, don't render anything.
  if (isHeadless) return null;

  const chartData = {
    datasets: [
      {
        data: [percentage, 100 - percentage],
        backgroundColor: ['#ffc067', '#1F2937'], // Colors for the filled and empty parts
        borderWidth: 0, // Remove the white border
      },
    ],
    labels: ['Engagement Time', 'Remaining'],
  };

  const options = {
    responsive: true,
    cutout: '70%', // Adjust for the donut hole size
    plugins: {
      tooltip: { enabled: false }, // Disable tooltips
      legend: { display: false },
    },
  };

  return <Doughnut data={chartData} options={options} />;
};

export default ReadingTimeChartComponent;

